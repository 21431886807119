import { BaseSyntheticEvent } from 'react';
import { TEngineBasketPutRequest } from '@src/interfaces/engineBasketPutRequest';

export enum KapiEngineHandlesOptions {
  ACCOMMODATION = 'ACCOMMODATION',
  BASKET = 'BASKET',
  CONSENTS = 'CONSENTS',
  CUSTOMER_DETAILS = 'CUSTOMER_DETAILS',
  DELIVERY = 'DELIVERY',
  DISCOUNTS = 'DISCOUNTS',
  EXTRAS = 'EXTRAS',
  INSURANCE = 'INSURANCE',
  LOGIN = 'LOGIN',
  PACKAGE = 'PACKAGE',
  PAYMENT = 'PAYMENT',
  PROTECTION_PLAN = 'PROTECTION_PLAN',
  TICKET = 'TICKET',
  TRANSPORT = 'TRANSPORT',
}

export type KapiEngineHandles = `${KapiEngineHandlesOptions}`;

export interface EngineMsgItem {
  categoryId: number;
  message: string;
}

export interface EngineItem {
  handle: KapiEngineHandles;
  mandatory: boolean;
  messages?: string[];
  name: string;
}

export interface EngineData {
  [key: string]: EngineItem[];
}

export interface InitResponse {
  data?: {
    engines?: EngineData;
    options: {
      NKBF_MAXTICKETS: number;
      STRIPE_PUBLIC_API: string;
    };
  };
  isSuccess: boolean;
}

export interface packageOptions {
  maxSelection: number;
}

type ContinueButtonCallback =
  | ((params?: unknown) => void)
  | ((params?: unknown) => Promise<void>)
  | ((e?: BaseSyntheticEvent | undefined) => Promise<void>);

export type EngineContext = {
  enableContinueButton: boolean;
  continueButtonCallback?: ContinueButtonCallback;
  continueButtonVariant?: 'primary' | 'secondary';
  isTicketStateDirty?: boolean;
  getBasketRequestState?: () => Promise<TEngineBasketPutRequest>;
  resetTicketState?: () => void;
  showBasket: boolean;
  showCompleteBookingLabel: boolean;
};

export type EngineInfo = {
  handle: string;
  name: string;
  route: string;
};
