import React from 'react';
import styles from './LoadingSVG.module.scss';

const LoadingSVG = () => (
  <div className={styles.container}>
    <img alt="Loading..." data-testid="loadingSVG" src="/loading-icon-2024.svg" />
  </div>
);

export default LoadingSVG;
