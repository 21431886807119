import { InitResponse } from '@src/api/useInit';
import { ENGINES } from '@src/constants';

type InitEngines = InitResponse['engines'];

function findFirstEngine(engines: InitEngines | undefined | null) {
  if (!engines) return undefined;
  return ENGINES.find((engine) => engine.handle === engines?.[0].handle);
}

export { findFirstEngine };
