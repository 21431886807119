/* eslint-disable @typescript-eslint/no-explicit-any */

import { InterfaceBPToken } from '../tokens';

export const ObjectIsBPToken = (object: any): object is InterfaceBPToken => {
  if ((object as InterfaceBPToken)?.t && (object as InterfaceBPToken)?.c) {
    return true;
  }

  return false;
};
