import '../../index.scss';
import '../../styles/globals.scss';

import * as KBF from '@src/lib/kbf';

import { Footer, Header, Modal } from '@src/components';
import { useEffect, useRef } from 'react';

import { ErrorBoundaryWrapper } from '../ErrorBoundary';
import { HandleAppLoading } from '../HandleAppLoading/HandleAppLoading';
import { Outlet } from 'react-router-dom';
import { useAppearanceStore } from '@src/store/useAppearanceStore';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useModalStore } from '@src/store/useModalStore';
import { useSelectClientTheme } from '@src/hooks/useSelectClientTheme/useSelectClientTheme';
import useUpdateToastWidth from '@src/hooks/useUpdateToastWidth/useUpdateToastWidth';

declare global {
  interface Window {
    Weglot: {
      initialize: ({ api_key }: { api_key: string }) => void;
    };
  }
}

const AppLayout = () => {
  const { displayShellComponents, setDisplayShellComponents } = useAppearanceStore();
  const { status: modalStatus, content: modalContent } = useModalStore();
  const { options } = useInitResponseStore(({ options }) => ({ options }));
  const { current: weglotScript } = useRef(document.createElement('script'));

  useUpdateToastWidth();
  useSelectClientTheme();

  // Does it make sense to have this in a layout component?
  useEffect(() => {
    KBF.runFrameSizing('home');
  }, []);

  useEffect(() => {
    KBF.whenAppearanceSet().then(setDisplayShellComponents);
    KBF.initWindowMode(window);
  }, [setDisplayShellComponents]);

  useEffect(() => {
    const { WEGLOT_API_KEY } = process.env;

    if (options?.WEGLOT_TRANSLATION && WEGLOT_API_KEY && !weglotScript.src) {
      weglotScript.src = 'https://cdn.weglot.com/weglot.min.js';
      weglotScript.async = true;
      weglotScript.defer = true;
      weglotScript.onload = () => {
        if (window.Weglot) {
          window.Weglot.initialize({
            // eslint-disable-next-line camelcase
            api_key: WEGLOT_API_KEY,
          });
        }
      };
      document?.head?.appendChild(weglotScript);
    }
  }, [options?.WEGLOT_TRANSLATION, weglotScript]);

  return (
    <>
      <Header showHeader={displayShellComponents} />
      <main className="App">
        <ErrorBoundaryWrapper>
          <>
            <HandleAppLoading />
            {modalStatus && modalContent && <Modal />}
            <Outlet />
          </>
        </ErrorBoundaryWrapper>
      </main>
      {displayShellComponents && <Footer ShowKlarnaFAQs={options?.ENABLE_KLARNA_STRIPE_ELEMENTS} />}
    </>
  );
};

export default AppLayout;
