import { type RouteObject } from 'react-router-dom';
import {
  CUSTOMER_DETAILS_ROUTE,
  ENGINE_NAV_DATA,
  FORGOTTEN_PASSWORD_ROUTE,
  LOGIN_ROUTE,
  NEW_CUSTOMER_ROUTE,
  BPEngineHandlesOptions,
  RESTART_ROUTE,
} from '@src/constants';

import { AuthWrapper } from './components/AuthWrapper';
import { Suspense, lazy } from 'react';
import { AppLayout } from './components';

const ProtectedRoute = lazy(() => import('./components/ProtectedRoute/ProtectedRoute'));
const CustomerDetailsPage = lazy(() => import('@src/pages/CustomerDetails'));
const CustomerLoginPage = lazy(() => import('@src/pages/CustomerLogin'));
const NewCustomerPage = lazy(() => import('@src/pages/NewCustomerPage'));
const NotFoundPage = lazy(() => import('@src/pages/404'));
const PaymentPage = lazy(() => import('@src/pages/Payment'));
const BookingStatus = lazy(() => import('@src/pages/BookingStatus'));
const ForgotPasswordForm = lazy(() => import('./engines/CustomerLogin/ForgottenPassword'));
const TicketsPage = lazy(() => import('@src/pages/Tickets'));
const ExtrasPage = lazy(() => import('@src/pages/Extras'));
const AccommodationPage = lazy(() => import('@src/pages/Accommodation'));
const TransportPage = lazy(() => import('@src/pages/Transport'));
const CheckoutStatusPage = lazy(() => import('@src/pages/CheckoutStatusPage'));

const {
  [BPEngineHandlesOptions.TICKET]: tickets,
  [BPEngineHandlesOptions.EXTRAS]: extras,
  [BPEngineHandlesOptions.ACCOMMODATION]: accommodation,
  [BPEngineHandlesOptions.PAYMENT]: payment,
  [BPEngineHandlesOptions.BOOKING_STATUS]: bookingStatus,
  [BPEngineHandlesOptions.TRANSPORT]: transport,
  [BPEngineHandlesOptions.CHECKOUT_STATUS]: checkoutStatus,
} = ENGINE_NAV_DATA;

const routes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      // AuthProvider routes
      {
        element: <AuthWrapper />,
        path: '/',
        children: [
          {
            path: RESTART_ROUTE,
          },
          {
            path: tickets.route,

            element: (
              <Suspense>
                <TicketsPage />
              </Suspense>
            ),
          },
          {
            path: extras.route,
            element: (
              <Suspense>
                <ExtrasPage />
              </Suspense>
            ),
          },
          {
            path: accommodation.route,
            element: (
              <Suspense>
                <AccommodationPage />
              </Suspense>
            ),
          },
          {
            path: transport.route,
            element: (
              <Suspense>
                <TransportPage />
              </Suspense>
            ),
          },
          {
            path: CUSTOMER_DETAILS_ROUTE,
            element: (
              <Suspense>
                <ProtectedRoute>
                  <CustomerDetailsPage />
                </ProtectedRoute>
              </Suspense>
            ),
          },
          {
            path: LOGIN_ROUTE,
            element: (
              <Suspense>
                <CustomerLoginPage />
              </Suspense>
            ),
          },
          {
            path: NEW_CUSTOMER_ROUTE,
            element: (
              <Suspense>
                <NewCustomerPage />
              </Suspense>
            ),
          },
          {
            path: FORGOTTEN_PASSWORD_ROUTE,
            element: (
              <Suspense>
                <ForgotPasswordForm />
              </Suspense>
            ),
          },
          {
            path: payment.route,
            element: (
              <Suspense>
                <PaymentPage />
              </Suspense>
            ),
          },
          {
            path: bookingStatus.route,
            element: (
              <Suspense>
                <BookingStatus />
              </Suspense>
            ),
          },
        ],
      },
      // Error Routes
      {
        path: '/404',
        element: <NotFoundPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
      // Unprotected payment routes
      {
        path: checkoutStatus.route,
        element: <CheckoutStatusPage />,
      },
    ],
  },
];

export default routes;
