const isLocalStorageAvailable = (() => {
  // Recommended way of feature detecting localStorage
  let storage;
  try {
    storage = window['localStorage'];
    const x = '__bp_storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return e instanceof DOMException && e.name === 'QuotaExceededError' && storage && storage.length !== 0;
  }
})();
const isCookieAvailable = 'cookie' in document;

export { isLocalStorageAvailable, isCookieAvailable };
