/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';

import { InterfaceBPToken } from '@src/interfaces/tokens';
import useBpTokenStore from '@src/store/useBpTokenStore';

interface BaseAnalytics {
  tenant_id?: string;
  user_id?: string;
  basket_id?: number;
  client_id?: number;
  client_name?: string;
  package_id?: number;
  metapackage_id?: string;
}

interface AnalyticsEvent {
  event: string;
  [key: PropertyKey]: any;
}

// Base interface with common properties
interface BaseEccomerceItem {
  item_id: number | string;
  item_name: string;
  currency?: string;
  index?: number;
  quantity?: number;
  [key: PropertyKey]: any;
}

interface EventConfig {
  canRepeat?: boolean; // Event can fire if previous event is exactly the same (default: true)
  trackOnceBasedOnValue?: string; // Cookie based on string to only allow that key to be fired once per Cookie life
}

// Specific interface for formatted items
export interface EccomerceItem extends BaseEccomerceItem {
  price: string; // price as a string
}

// Specific interface for unformatted items
export interface EccomerceItemUnformatted extends BaseEccomerceItem {
  price: number; // price as a number
}

export interface EcommerceEvent {
  event?: string;
  ecommerce?: {
    currency: string;
    value: number | string;
    items: EccomerceItem[];
    [key: PropertyKey]: any;
  };
  others: any;
}

function isEqual(obj1: object, obj2: object) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const trackingCookie = {
  prefix: 't-',

  set(key: string): void {
    Cookies.set(`${this.prefix}${key}`, 'true', { expires: 7 });
  },

  get(key: string): string | undefined {
    return Cookies.get(`${this.prefix}${key}`);
  },
};

class G4Analytics {
  userId = '';
  bpToken: InterfaceBPToken | null = null;
  baseAnalytics: BaseAnalytics = {};
  locationId = 0;
  private lastTrackedEcommerceEvent = {};
  private lastTrackedEvent = {};

  constructor() {
    useBpTokenStore.subscribe?.(({ bpTokenExtractedUnverified }) => {
      this.baseAnalytics = {
        tenant_id: bpTokenExtractedUnverified?.t,
        package_id: bpTokenExtractedUnverified?.p,
        client_id: bpTokenExtractedUnverified?.c,
        basket_id: bpTokenExtractedUnverified?.b,
      };
    });
  }

  init() {
    this.initTracking(process.env.REACT_APP_GTM_ID || '');
    console.log('GTM initialized....');
  }

  sendPageView(pagePath: string) {
    TagManager?.dataLayer({
      dataLayer: {
        ...this.baseAnalytics,
        event: 'pageview',
        pagePath: pagePath,
        ecommerce: undefined,
      },
    });
  }

  sendEvent(e: AnalyticsEvent, { canRepeat = true, trackOnceBasedOnValue }: EventConfig = {}) {
    if (!canRepeat && isEqual(this.lastTrackedEcommerceEvent, e)) return;
    if (!!trackOnceBasedOnValue && trackingCookie.get(trackOnceBasedOnValue)) return;

    const { event, ...rest } = e;
    TagManager?.dataLayer({
      dataLayer: {
        event: event,
        ...this.baseAnalytics,
        ...rest,
        ecommerce: undefined,
      },
    });
    this.lastTrackedEvent = e;
    if (trackOnceBasedOnValue) trackingCookie.set(trackOnceBasedOnValue);
  }

  sendEcommerceEvent(data: EcommerceEvent, { canRepeat = true, trackOnceBasedOnValue }: EventConfig = {}) {
    if (!canRepeat && isEqual(this.lastTrackedEcommerceEvent, data)) return;
    if (!!trackOnceBasedOnValue && trackingCookie.get(trackOnceBasedOnValue)) return;

    const obj = {
      dataLayer: {
        ...this.baseAnalytics,
        event: data.event || 'purchase',
        ...data.others,
        ecommerce: {
          ...data.ecommerce,
          items: data?.ecommerce?.items.map((item, index) => ({
            ...item,
            index,
          })),
        },
      },
    };
    TagManager?.dataLayer(obj);
    this.lastTrackedEcommerceEvent = data;
    if (trackOnceBasedOnValue) trackingCookie.set(trackOnceBasedOnValue);
  }

  initTracking(clientGtmId: string) {
    TagManager.initialize({
      gtmId: clientGtmId.trim(),
    });
  }
}

const tracking = new G4Analytics();

export default tracking;
