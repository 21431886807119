import Cookies from 'js-cookie';
import { isLocalStorageAvailable, isCookieAvailable } from '@src/lib/availableLocalStores';

async function updateTokenStore(appToken: string | null, tokenType: string) {
  if (!appToken) return;

  if (isLocalStorageAvailable) {
    localStorage.setItem(tokenType, appToken);
    return;
  }

  if (isCookieAvailable) {
    Cookies.set(tokenType, appToken, { expires: 3650 });
    return;
  }

  throw new Error('No storage method available');
}

// Change name and remove the other method once everything changes
export const storeAppToken = async (tokenType: string, appToken: string | null) => {
  try {
    await updateTokenStore(appToken, tokenType);
  } catch (error) {
    console.error('Error storing BP token in local store:', error);
  }
};

export default storeAppToken;
