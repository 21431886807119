import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { ENGINES } from '@src/constants';
import { EngineNavItem } from '@src/interfaces/engineNavigation';

const useLayoutConfiguration = () => {
  const location = useLocation();

  const matchingRoute = useMemo(() => {
    return ENGINES.reduce((acc, engine) => {
      if (engine.route === location.pathname) return engine as EngineNavItem;
      if (engine.subRoutes) {
        const subRoutes = Object.keys(engine.subRoutes).map((key) => {
          // @ts-expect-error This is something that needs looking into. Chances are we should
          // restructure the engine object to remove sub routes.
          return engine.subRoutes[key];
        });
        const matchingSubRoute = subRoutes.find((subRoute) => subRoute.route === location.pathname);
        if (matchingSubRoute) return matchingSubRoute as EngineNavItem;
      }
      return acc;
    }, undefined as EngineNavItem | undefined);
  }, [location.pathname]);

  return {
    cols: matchingRoute?.layout?.columns ?? 1,
    showNavStepper: matchingRoute?.layout?.displayNav ?? false,
    showSideBar: matchingRoute?.layout?.showSideBar ?? true,
    currentEnginePath: matchingRoute?.route,
    engineContainerShouldGrow: matchingRoute?.layout?.engineContainerShouldGrow ?? false,
  };
};

export default useLayoutConfiguration;
