import { ServiceMessageVariants } from '@kaboodle-solutions/design-library';
import { BaseBasket } from '@src/api/useBasket/useBasket.types';
import { ToastNotification } from '@src/components/ToastNotification';
import { KapiEngineHandlesOptions } from '@src/interfaces/engineData';
import {
  ALLOW_LIST_ADDRESS_VALIDATION_ERROR_REGEX,
  ALLOW_LIST_DOMAIN_VALIDATION_ERROR_REGEX,
} from './hasBlockingValidationError';

const INVALID_EMAIL_ERROR = 'The email address you entered cannot be used to make a purchase using this event link';

const allowedValidationErrors: { [key in KapiEngineHandlesOptions]?: { regex: RegExp; override?: string }[] } = {
  [KapiEngineHandlesOptions.CUSTOMER_DETAILS]: [
    {
      regex: ALLOW_LIST_ADDRESS_VALIDATION_ERROR_REGEX,
      override: INVALID_EMAIL_ERROR,
    },
    {
      regex: ALLOW_LIST_DOMAIN_VALIDATION_ERROR_REGEX,
      override: INVALID_EMAIL_ERROR,
    },
  ],
};

/**
 * Displays generic basket errors based on the provided basket and engine.
 * Optionally handles side effects for errors and validation errors.
 *
 * @param {BaseBasket | null} basket - The basket object to check for errors. If null, no basket errors will be processed.
 * @param {KapiEngineHandlesOptions | undefined} engine - The engine options used for error handling. If undefined, default engine behavior is used.
 * @param {Object} [sideEffects] - Optional side effects to handle specific scenarios.
 * @param {Function} [sideEffects.onError] - Callback function to execute when a general error occurs.
 * @param {Function} [sideEffects.onValidationError] - Callback function to execute when a validation error occurs.
 *
 * @returns {void}
 */
export default function displayBasketErrors(
  basket: BaseBasket | null,
  engine: KapiEngineHandlesOptions | undefined,
  sideEffects?: { onError?: () => void; onValidationError?: () => void }
) {
  if (!engine) return;

  if (basket?.errors) {
    basket?.errors?.forEach(({ message }) => {
      ToastNotification({
        testId: 'engine-state-basket-error-notification',
        content: message,
        variant: ServiceMessageVariants.Error,
        autoClose: false,
      });
    });
    sideEffects?.onError?.();
  }

  const validationErrorsForThisEngine = basket?.validationErrors?.filter((error) => error.engine === engine);

  if (validationErrorsForThisEngine) {
    for (const { message } of validationErrorsForThisEngine) {
      const allowList = allowedValidationErrors[engine];
      const allowedError = allowList?.find(({ regex }) => regex.test(message));

      // If there's no allowed error or no allow list, trigger the toast
      if (!allowList || allowedError) {
        ToastNotification({
          testId: 'engine-state-basket-info-notification',
          content: allowedError?.override || message,
          variant: ServiceMessageVariants.Info,
          autoClose: false,
        });
      }
    }
    sideEffects?.onValidationError?.();
  }
}
