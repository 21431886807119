/* eslint-disable */

import { KbfConfig, PostMessagePayload } from '../interfaces/kbf';

let debug: boolean = true;
let resolveReady: Function;

const isReady = true;
const fetchWhenReady = function () {
  return new Promise((resolve) => {
    resolveReady = resolve;
  });
};

let hasParentWindow: boolean = false;

let thisWindow: Window & {
  failedAfterTimeout?: NodeJS.Timeout;
  gtag?: Function;
};

let appearanceCallback: Function;
let lastContentHeight: number = 0;

const debugLog = function (...args: any[]) {
  // @ts-ignore
  if (debug) console.log.apply(null, arguments);
};

const whenReady = function (): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    if (isReady) {
      resolve();
      return;
    }

    // OK, we're gonna wait a while (10s) before bailing out...
    // We use the 'thisWindow' to track timeouts. Strict mode React in local dev causes double execution.
    if (null === thisWindow) return;
    if (thisWindow.failedAfterTimeout) return;
    thisWindow.failedAfterTimeout = setTimeout(() => {
      reject('Waiting too long to be ready');
    }, 10000);
    fetchWhenReady().then(() => {
      clearTimeout(thisWindow.failedAfterTimeout);
      resolve();
    });
  });
};

const sendPostMessage = function (payload: PostMessagePayload) {
  if (hasParentWindow) {
    // @todo - review '*' (for this embedded version)
    thisWindow.parent.postMessage(JSON.stringify(payload), '*');
  }
};

const processDownstreamPostMessagePayload = function (payload: PostMessagePayload) {
  switch (payload.action || 'unknown') {
    case 'handshakeDown':
      break;
    case 'setConfig':
      if (payload.config) setAdditionalConfig(payload.config);
      break;
    default:
      debugLog('Unknown action:', payload);
  }
};

const setAdditionalConfig = function (config: KbfConfig) {
  // Appearance
  if (config.appearance && 'object' === typeof config.appearance) {
    if (appearanceCallback) {
      // Change this config.appearance to be displayShellComponents
      appearanceCallback(config.appearance);
    }
  }
};

const whenAppearanceSet = function () {
  return new Promise((resolve: (value: boolean) => void) => {
    appearanceCallback = resolve;
  });
};

const initWindowMode = function (window: Window) {
  thisWindow = window;
  if (window.parent && window.parent !== window) {
    setupEmbeddedMode();
  }
};

const setupEmbeddedMode = function () {
  hasParentWindow = true;
  thisWindow.addEventListener(
    'message',
    (event) => {
      // @todo consider restrictions on message source host - although we're embedding anywhere...
      // if (event.origin !== ????.src) return;
      if (event.data && '{' === event.data[0]) {
        processDownstreamPostMessagePayload(JSON.parse(event.data));
      }
    },
    false
  );
  // Send a handshake ASAP
  sendPostMessage({ action: 'handshakeUp' });
  // Periodic size check (we also trigger manually when we KNOW size might change)
  runFrameSizing('init-window');
  setInterval(runFrameSizing, 200);
};

const runFrameSizing = function (src: string = 'auto') {
  if (!hasParentWindow || !thisWindow) return;
  const appElement = thisWindow.document.getElementById('root');
  if (appElement) {
    let newHeight = appElement.offsetHeight;
    if (newHeight !== lastContentHeight) {
      debugLog({ height: newHeight, s: src });
      lastContentHeight = newHeight;
      sendPostMessage({ action: 'height', args: { newHeight: newHeight } });
    }
  }
};

export { initWindowMode, whenAppearanceSet, whenReady, runFrameSizing };
