import { useEffect, useState } from 'react';
import { type InternalAxiosRequestConfig } from 'axios';
import { BPAPI } from '@src/api/mockedApi';
import useBpTokenStore from '@src/store/useBpTokenStore';

function axiosInterceptorRequest(
  config: InternalAxiosRequestConfig,
  userIdToken: string | null,
  bookingPortalToken: string | null
) {
  if (userIdToken) {
    config.headers['Authorization'] = `Bearer ${userIdToken}`;
  }
  if (bookingPortalToken) {
    config.headers['bp'] = `${bookingPortalToken}`;
  }
  return config;
}

const useAxiosInterceptors = (userIdToken: string | null) => {
  const [isFirstBearerTokenHeaderReady, setIsFirstBearerTokenHeaderReady] = useState(false);
  const [isFirstBpTokenHeaderReady, setIsFirstBpTokenHeaderReady] = useState(false);
  const { setBpToken, bpToken } = useBpTokenStore();

  useEffect(() => {
    const responseInterceptor = BPAPI.interceptors.response.use((response) => {
      if (response.headers['bp']) {
        setBpToken(response.headers['bp']);
      }
      return response;
    });

    if (userIdToken) {
      BPAPI.defaults.headers['Authorization'] = `Bearer ${userIdToken}`;
      setIsFirstBearerTokenHeaderReady(true);
    }
    if (bpToken) {
      BPAPI.defaults.headers['bp'] = `${bpToken}`;
      setIsFirstBpTokenHeaderReady(true);
    }

    return () => {
      BPAPI.interceptors.response.eject(responseInterceptor);
    };
  }, [userIdToken, bpToken, setBpToken]);

  return { isFirstBearerTokenHeaderReady, isFirstBpTokenHeaderReady };
};

export { axiosInterceptorRequest, useAxiosInterceptors };
