/**
 * This file contains all query keys currently being used
 * It helps by centering all keys in one place
 * so we know what keys we can interact with (call, invalidate, refetch, etc...)
 *
 * All query keys constants should start with QK (QueryKey) prefix e.g: QK_TICKETS
 * All mutation keys constants should start with MK (MutationKey) prefix e.g: MK_TICKETS
 */

export const QK_SHOW_KABOODLE_LOADING = 'show-kaboodle-loading-spinner';
export const QK_SHOW_3_DOTS_LOADING = 'show-3-dots-loading-spinner';

// Query Keys
export const QK_CURRENT_CLIENT_INFO = 'current-client-info';
export const QK_ALL_CLIENTS_INFO = 'all-clients-info';
export const QK_TICKETS = 'tickets';
export const QK_INIT = 'init';
export const QK_APP_BASKET = 'app-basket';
export const QK_CUSTOMER_DETAILS_DATA_CAPTURE = 'customer-details-data-capture';
export const QK_ADDRESS_LOOKUP_SUGGESTIONS = 'address-lookup-suggestions';
export const QK_ADDRESS_LOOKUP_DETAILS = 'address-lookup-details';
export const QK_PAYMENT_INTENT = 'payment_intent';
export const QK_STRIPE_INIT = 'stripe_init';
export const QK_GET_PAYMENT_OPTIONS = 'payment-options';
export const QK_GET_BOOKING_STATUS = 'get-booking-status';
export const QK_FIREBASE_ANON_SIGN_IN = 'ANON_SIGN_IN_FIREBASE';
export const QK_ACCOMMODATIONS = 'accommodations';
export const QK_ACCOMMODATION_GROUPS = 'accommodation-groups';
export const QK_ACCOMMODATION_ROOMS = 'accommodation-rooms';
export const QK_EXTRAS = 'extras';

// Mutation Keys
export const MK_INIT = 'init-mutation';
export const MK_COMPLETE_BASKET = 'complete-basket';
export const QK_PUT_PAYMENT_INTENT = 'put_payment_intent';
export const QK_APP_BASKET_MUTATION = 'app-basket-mutation';
export const QK_PENDING_CUSTOMER_MUTATION = 'create-pending-customer';
export const MK_CUSTOMER_SIGN_IN = 'customer-sign-in-mutation';
