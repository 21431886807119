import { create } from 'zustand';

interface BasketExpiryTimerState {
  basketId: number | null;
  setBasketId: (newBasketId: number) => void;
  timeRemaining: number | null;
  setTimeRemaining: (newTime: number) => void;
}

/**
 * We store the basket id here to ensure that we aren't rewriting the timer on
 * every fetch of the basket
 */
export const useBasketExpiryTimerStore = create<BasketExpiryTimerState>()((set) => ({
  basketId: null,
  setBasketId: (newBasketId: number) => set(() => ({ basketId: newBasketId })),
  timeRemaining: null,
  setTimeRemaining: (newTime: number) => set(() => ({ timeRemaining: newTime })),
}));
