// This locale can come from browser, env file or somewhere else we decide later
const locale = 'en-GB';
const preferredCurrency = 'GBP';

/**
 * Native JS currency formatter that receives Number(s)/BigInt(s) and
 * returns a string with currency format based on preferredCurrency and locale
 *
 * Right now it uses hard-coded locale and preferredCurrency,
 * but this will come dynamically in the future
 */

export const currencyFormatter = new Intl.NumberFormat(locale, {
  style: 'currency',
  currency: preferredCurrency,
});

export function formatTrackingPrice(...numbers: (number | undefined)[]) {
  return `${(numbers.reduce((acc: number, cur: number | undefined) => acc + (cur || 0), 0) / 100).toFixed(2)}`;
}
