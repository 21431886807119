import { QK_APP_BASKET, QK_APP_BASKET_MUTATION, QK_SHOW_3_DOTS_LOADING } from '@src/api/queryKeys';

import { BPAPI } from '@src/api/mockedApi';
import { includesMutationOrQueryKey } from '@src/lib/includesMutationOrQueryKey';
import { queryClient } from '@src/api/queryClient';
import { useMutation } from '@tanstack/react-query';

function useDeleteBasket() {
  const mutation = useMutation({
    mutationKey: [QK_SHOW_3_DOTS_LOADING, QK_APP_BASKET_MUTATION, 'RESTART_BASKET'],
    mutationFn: async () => BPAPI.delete('/basket'),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => includesMutationOrQueryKey(query, QK_APP_BASKET),
      });
    },
  });

  return {
    ...mutation,
    deleteBasketRequest: mutation.mutateAsync,
  };
}

export { useDeleteBasket };
