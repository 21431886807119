import { Heading } from '@kaboodle-solutions/design-library';
import React, { ReactNode } from 'react';
import styles from './infoCard.module.scss';

interface InfoCardProps {
  title: string;
  children: ReactNode;
  testId?: string;
}

const InfoCard = ({ title, children, testId }: InfoCardProps) => {
  return (
    <div className={styles.infoCard} data-testid={`${testId}-wrapper`}>
      <Heading level="h3" customClass={styles.headingTitle} isBold testId={`${testId}-title`}>
        {title}
      </Heading>
      <div className={styles.infoCard__content} data-testid={`${testId}-content`}>
        {children}
      </div>
    </div>
  );
};

export default InfoCard;
