import { Steps } from '@src/store/useNavStepperStages';

/**
 * This function will be responsible for redirecting the user back to the last step he is allowed to be in
 */
export const handleCheckCurrentStep = (steps: Steps, navigate: (path: string) => void) => {
  // First engine will always be navigable to
  let lastNavEnabledEngine = steps[0];
  steps.forEach((engine) => {
    if (engine.enableNavigation) {
      lastNavEnabledEngine = engine;
    }

    if (location.pathname.includes(engine.linkUrl) && !engine.enableNavigation && engine.basketChecked) {
      navigate(lastNavEnabledEngine.linkUrl);
    }
  });
};
