import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from './i18n';
import tracking from './lib/tracking';

tracking.init();

const renderPortal = async () => {
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </I18nextProvider>
    </React.StrictMode>
  );
};

renderPortal();
