import { ERROR_BOUNDARY_TEMPLATES, TEMPLATE_KEYS } from './Templates';
import { ErrorBoundary as LibraryErrorBoundary, useErrorBoundary as useLibErrorBoundary } from 'react-error-boundary';
import React from 'react';

type ErrorBoundaryArgs = {
  error?: string;
  template: TEMPLATE_KEYS;
};

/**
 * This hook is a wrapper for the react-error-boundary library. It works
 * alongside the ErrorBoundaryWrapper component. Use this over the library
 * hook.
 */
function useErrorBoundary() {
  const methods = useLibErrorBoundary<ErrorBoundaryArgs>();
  return methods;
}

/**
 * This is simply a component to handle rendering the fallback element
 * for the wrapper component below. Extend this by adding more templates
 * to the ERROR_BOUNDARY_TEMPLATES object in Templates.tsx
 */
function ErrorBoundary({ error }: { error: ErrorBoundaryArgs }) {
  const { template, error: errorMessage } = error;
  if (!template) return ERROR_BOUNDARY_TEMPLATES.default('An error occurred');
  return ERROR_BOUNDARY_TEMPLATES[template](errorMessage ?? 'An error occurred');
}

/**
 * This component is a wrapper for the react-error-boundary library. It provides a way to handle errors
 * in a more dev-friendly way, where we can provide a template for the error and the unique message.
 *
 * Use this component to wrap areas you want to have a boundary ⚠️
 */
function ErrorBoundaryWrapper({ children }: { children: React.ReactNode }) {
  return <LibraryErrorBoundary FallbackComponent={ErrorBoundary}>{children}</LibraryErrorBoundary>;
}

export { ErrorBoundary, ErrorBoundaryWrapper, useErrorBoundary };
export type { ErrorBoundaryArgs };
