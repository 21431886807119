import { Step, useNavStepperStages } from '@src/store/useNavStepperStages';
import { useNavigateWithQueryParams } from '../useNavigateWithQueryParams/useNavigateWithQueryParams';
import { useCallback, useRef, useEffect } from 'react';

export const useDynamicNavigate = () => {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { steps: defaultSteps } = useNavStepperStages();
  const stepsRef = useRef(defaultSteps);

  useEffect(() => {
    stepsRef.current = defaultSteps;
  }, [defaultSteps]);

  const navigateToNextEngine = useCallback(
    (currentRoute: string, customSteps?: Step[]) => {
      const currentSteps = customSteps || stepsRef.current;

      const nextIndex = currentSteps.findIndex((step) => step.linkUrl === currentRoute);

      if (nextIndex === -1) {
        console.error('Current route not found in steps');
        return;
      }

      const nextEngine = currentSteps[nextIndex + 1];

      if (nextEngine) {
        navigateWithQueryParams(nextEngine.linkUrl);
        window.scrollTo(0, 0);
      }
    },
    [navigateWithQueryParams]
  );

  return { navigateToNextEngine };
};
