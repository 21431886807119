import { createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import deTranslations from '../locales/de/translation.json';
import enTranslations from '../locales/en/translation.json';

const instance = createInstance();

export const defaultNS = 'en';
export const resources = {
  en: enTranslations,
  de: deTranslations,
} as const;

instance
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS,
    resources,
    fallbackLng: 'en',
    ns: ['en', 'de'],
    detection: {
      order: ['navigator', 'localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
      cookieOptions: {
        sameSite: 'strict',
        secure: true,
      },
    },
  });

export default instance;
