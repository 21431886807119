import { useCallback } from 'react';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useNavigate } from 'react-router-dom';

/**
 * This hook is responsible for persisting the init params after navigation
 */

export const useNavigateWithQueryParams = () => {
  const navigate = useNavigate();
  const { initParams } = useInitResponseStore();

  const navigateWithQueryParams = useCallback(
    (path: string | number) => {
      navigate(`${path}${initParams || ''}`);
    },
    [navigate, initParams]
  );

  return { navigateWithQueryParams };
};
