import { type Mutation, type Query } from '@tanstack/react-query';

const includesMutationOrQueryKey = (mutationOrQuery: Mutation | Query, key: string): boolean => {
  if ('queryKey' in mutationOrQuery.options) {
    return mutationOrQuery.options.queryKey?.includes(key) ?? false;
  }

  if ('mutationKey' in mutationOrQuery.options) {
    return mutationOrQuery.options.mutationKey?.includes(key) ?? false;
  }

  return false;
};

export { includesMutationOrQueryKey };
