import React from 'react';
import { cssTransition, toast, ToastContainer } from 'react-toastify';
import { ServiceMessage, type ServiceMessageProps } from '@kaboodle-solutions/design-library';
import styles from './toastNotification.module.scss';

const fade = cssTransition({
  enter: 'toastFadeIn',
  exit: 'toastFadeOut',
});

export interface ToastProps extends ServiceMessageProps {
  autoClose?: number | boolean | undefined;
}

export const ToastNotification = ({ testId, content, variant, autoClose }: ToastProps) => {
  const canAutoClose = autoClose !== undefined ? autoClose : true;
  return toast(<ServiceMessage testId={testId} content={content} variant={variant} />, {
    autoClose: canAutoClose === true ? 5000 : false,
    transition: fade,
  });
};

export default function ToastNotificationWrapper() {
  return (
    <div className={styles.toastNotification}>
      <ToastContainer
        theme="colored"
        className="toastifyCustomContainer"
        bodyClassName="toastifyCustomBodyContainer"
        transition={fade}
        closeButton={false}
        draggable={false}
        closeOnClick={true}
      />
    </div>
  );
}
