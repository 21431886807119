import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { type ReactNode } from 'react';
import { Link, type LinkProps } from 'react-router-dom';

type Props = LinkProps &
  React.RefAttributes<HTMLAnchorElement> & {
    children: ReactNode;
    to: string;
  };

export const LinkWithQueryParams = ({ to, children, ...rest }: Props): ReactNode => {
  const { initParams } = useInitResponseStore();

  return (
    <Link {...rest} to={`${to}${initParams || ''}`}>
      {children}
    </Link>
  );
};
