import { retrieveAppToken } from '@src/store/persistent/retrieveAppToken/retrieveAppToken';
import storeAppToken from '@src/store/persistent/storeAppToken/storeAppToken';
import { StateStorage } from 'zustand/middleware';

/**
 * This is the declaration of a custom storage method, to be used with zustand persistant functionality.
 * It uses retrieveAppToken and storeAppToken to find what storages are available to be used (IndexedDB, LocalStorage, Cookie - in this order)
 * and creates get, set, and remove functions based on that
 */
const customStorageDefinition: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await retrieveAppToken(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await storeAppToken(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    // Not implemented yet
    console.log(name, `Remove item call on ${name}`);
  },
};

export default customStorageDefinition;
