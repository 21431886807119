import { useCallback, useMemo } from 'react';

import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useShallow } from 'zustand/react/shallow';
import { useTranslation } from 'react-i18next';

const useCurrencyFormatter = (overrideCurrencyCode?: string) => {
  const { t } = useTranslation(['zeroValuePricesDisplay']);

  const { currency, _hasInitResponseStoreHydrated, options } = useInitResponseStore(
    useShallow(({ currency, _hasInitResponseStoreHydrated, options }) => ({
      currency,
      _hasInitResponseStoreHydrated,
      options,
    }))
  );
  const isSuccess = _hasInitResponseStoreHydrated && currency !== null;
  const isError = _hasInitResponseStoreHydrated && currency === null;
  const isLoading = _hasInitResponseStoreHydrated === false;

  const locale = useMemo(() => navigator.language ?? 'en-GB', []);
  const decimalPlaces = useMemo(() => currency?.exponent ?? 2, [currency?.exponent]);
  const currencyCode = useMemo(
    () => (overrideCurrencyCode || currency?.code) ?? 'GBP',
    [currency?.code, overrideCurrencyCode]
  );
  const handleDisplayZero = useMemo(() => options?.NKBF_ZERO_PRICE_DISPLAY ?? null, [options?.NKBF_ZERO_PRICE_DISPLAY]);

  const format = useCallback(
    (value: number, ignoreZeroValuePackageOption?: boolean) => {
      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol',
      });
      const formattedValue = formatter?.format(value / 10 ** decimalPlaces);

      if (value === 0 && !ignoreZeroValuePackageOption) {
        switch (handleDisplayZero) {
          case 2:
            return '';
          case 3:
            return t('free');
          case 4:
            return t('included');
          // Not set or default
          default:
            return formattedValue;
        }
      } else {
        return formattedValue;
      }
    },
    [decimalPlaces, currencyCode, locale, handleDisplayZero, t]
  );

  return {
    format,
    isFormatFunctionReady: isSuccess,
    formatFunctionHasError: isError,
    isFormatFunctionLoading: isLoading,
  };
};

export default useCurrencyFormatter;
