import Cookies from 'js-cookie';
import { isLocalStorageAvailable, isCookieAvailable } from '@src/lib/availableLocalStores';

async function getTokenFromStore(tokenType: string) {
  if (isLocalStorageAvailable) {
    const token = localStorage.getItem(tokenType);
    if (token) return token;
  }

  if (isCookieAvailable) {
    const token = Cookies.get(tokenType);
    if (token) return token;
  }

  if (!isLocalStorageAvailable && !isCookieAvailable) {
    throw new Error('No storage method available');
  }
}

const retrieveAppToken = async (tokenType: string): Promise<string | undefined> => {
  try {
    const token = await getTokenFromStore(tokenType);
    return token;
  } catch (error) {
    console.error('Error retrieving BP token from local store:', error);
  }

  return undefined;
};

export { retrieveAppToken };
