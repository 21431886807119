import { useBasketQuery } from '@src/api/useBasket';
import styles from './paymentSidebar.module.scss';
import { PaymentCustomerDetails } from '../PaymentCustomerDetails';
import { BasketSummary } from '../Basket';

const PaymentSidebar = () => {
  const { data: basketData } = useBasketQuery();
  const customerEmail = basketData?.data?.customerDetails?.emailAddress;

  return (
    <aside className={styles.paymentSidebar}>
      <BasketSummary />
      <PaymentCustomerDetails customerEmail={customerEmail} />
    </aside>
  );
};
export default PaymentSidebar;
