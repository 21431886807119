import { AxiosError } from 'axios';

export class ReactQueryError extends Error {
  __sentryIgnore: boolean;
  response: AxiosError['response'];

  constructor(error: AxiosError, sentryIgnore = false) {
    super(`Expected ${error.response?.status ?? ''} error - ${error.message}`);

    this.__sentryIgnore = sentryIgnore;
    this.response = error.response;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ReactQueryError.prototype);
  }
}

export function isReactQueryError(error: unknown): error is ReactQueryError {
  return error instanceof ReactQueryError;
}
