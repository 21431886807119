import { useTranslation } from 'react-i18next';
import { Heading, Typography } from '@kaboodle-solutions/design-library';
import { CUSTOMER_DETAILS_ROUTE } from '@src/constants';
import styles from './paymentCustomerDetails.module.scss';
import { LinkWithQueryParams } from '../LinkWithQueryParams/LinkWithQueryParams';

export type PaymentCustomerDetailsProps = {
  customerEmail?: string;
};

const PaymentCustomerDetails = ({ customerEmail }: PaymentCustomerDetailsProps) => {
  const { t } = useTranslation('paymentEngine');

  return (
    <div className={styles.paymentEngineSideBar_customerDetails}>
      <div className={styles.paymentEngineSideBar_customerDetailsHeader}>
        <Heading level="h4" isBold>
          {t('sideBar.customerDetails.title')}
        </Heading>
        <LinkWithQueryParams to={CUSTOMER_DETAILS_ROUTE}>{t('sideBar.customerDetails.edit')}</LinkWithQueryParams>
      </div>
      <hr />
      <div className={styles.paymentEngineSideBar_customerDetailsContent}>
        <Typography tag="span">{customerEmail}</Typography>
      </div>
    </div>
  );
};

export default PaymentCustomerDetails;
