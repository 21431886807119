import { useCallback, useEffect, useRef } from 'react';
import isClickInsideElement from '@src/lib/checkClickInsideElement';
import { useModalStore } from '@src/store/useModalStore';
import styles from './Modal.module.scss';

const Modal = () => {
  const { status: modalStatus, content: modalContent, closeModal, canBeClosed } = useModalStore();
  const dialogRef = useRef<HTMLDialogElement | null>(null);

  useEffect(() => {
    if (!dialogRef.current) {
      return;
    }

    if (modalStatus === 'open') {
      if (!dialogRef.current.hasAttribute('open')) {
        dialogRef.current.showModal();
      }
    } else {
      if (dialogRef.current.hasAttribute('open')) {
        dialogRef.current.close();
      }
    }
  }, [modalStatus, modalContent]);

  const handleCloseModal = useCallback(
    (e: React.MouseEvent) => {
      if (dialogRef.current && !isClickInsideElement(e, dialogRef.current) && canBeClosed) {
        closeModal();
      }
    },
    [canBeClosed, closeModal]
  );

  return (
    <>
      {modalStatus === 'open' && (
        // TODO: Refactor this so that the onClick event instead becomes a listener.
        // Checking for clicks outside of the dialog doesn't required it to be assigned
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
        <dialog ref={dialogRef} onClick={handleCloseModal} className={styles.modalDialog} id="modal-dialog">
          <div className={styles.modalWrapper}>{modalContent}</div>
        </dialog>
      )}
    </>
  );
};

export default Modal;
