import { BasketError } from '@src/api/useBasket/useBasket.types';

export const ALLOW_LIST_DOMAIN_VALIDATION_ERROR_REGEX = /Customer email domain not on allowed list \(email: .*@.*\)/;
export const ALLOW_LIST_ADDRESS_VALIDATION_ERROR_REGEX = /Customer email not in allowed email group \(email: .*@.*\)/;
export const MULTIPLE_BOOKINGS_VALIDATION_ERROR_REGEX = /Customer has existing bookings/;

const blockingErrorRegexs = [ALLOW_LIST_ADDRESS_VALIDATION_ERROR_REGEX, ALLOW_LIST_DOMAIN_VALIDATION_ERROR_REGEX];

/**
 * Checks if there are any blocking validation errors in the provided list of validation errors.
 *
 * @param {BasketError[] | null | undefined} validationErrors - The list of validation errors to check. Can be an array of `BasketError` objects, `null`, or `undefined`.
 *
 * @returns {boolean} Returns `true` if there are blocking validation errors, otherwise `false`.
 */
export default function hasBlockingValidationError(validationErrors: BasketError[] | null | undefined) {
  if (!validationErrors || !validationErrors.length) return false;
  return blockingErrorRegexs.some((regex) => validationErrors.some(({ message }) => regex.test(message)));
}
