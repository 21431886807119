import { BasketTransportItem } from '@components/Basket/BasketContent/BasketContent.types';
import styles from '../basketContent.module.scss';
import { Typography } from '@kaboodle-solutions/design-library';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '../../../../styles/svg/delete-icon.svg?react';
import { useMemo } from 'react';
import { calculateLineTotalByCount } from '@components/Basket/BasketContent/BasketContent.helper';
import { getPropertiesFromCartItem } from '@components/Basket/BasketContent/BasketCartItem.helper';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter';

interface TransportCartItemProps {
  transportItem: BasketTransportItem;
  isfirstItem: boolean;
  mode: 'basket' | 'checkout' | 'completed' | undefined;
  engine: string;
  getEngineTitle: (engine: string) => string;
  onEngineNameClick: () => void;
  basketMutationStatus?: string;
  removeTransportItem?: (item: BasketTransportItem) => Promise<void>;
}

export const TransportCartItem = ({
  transportItem,
  isfirstItem,
  mode,
  engine,
  getEngineTitle,
  onEngineNameClick,
  basketMutationStatus,
  removeTransportItem,
}: TransportCartItemProps) => {
  const { t } = useTranslation();
  const { count, name, price } = getPropertiesFromCartItem(transportItem);

  const { format } = useCurrencyFormatter();

  const itemTotal = useMemo(
    () => (price?.value ? format(calculateLineTotalByCount({ count, price: price?.value })) : null),
    [format, count, price]
  );

  const renderTransportLegs = (item: BasketTransportItem) => {
    const outboundLeg = item.legs.find((leg) => leg.type === 'OUTBOUND');
    const returnLeg = item.legs.find((leg) => leg.type === 'RETURN');
    const journeyText = [];

    if (outboundLeg) {
      journeyText.push(`${outboundLeg.departureLocation} ${t('common:words.to')} ${outboundLeg.arrivalLocation}`);
    }

    if (returnLeg) {
      journeyText.push(`${returnLeg.departureLocation} ${t('common:words.to')} ${returnLeg.arrivalLocation}`);
    }

    return (
      <div className={styles.basketItem_rowDesc}>
        <Typography tag="span" customClass={styles.basketItem_name}>
          {journeyText.join(', ')}
        </Typography>
      </div>
    );
  };

  return (
    <>
      {isfirstItem && (mode === 'basket' || mode === 'checkout') && (
        <button
          id="basket-cart-engine-name-link"
          className={styles.basketItemEngineNameLink}
          onClick={onEngineNameClick}
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onEngineNameClick();
            }
          }}
        >
          <Typography tag="span" customClass={styles.basketItemEngineName}>
            {getEngineTitle(engine)}
          </Typography>
        </button>
      )}

      <Typography tag="span" isBold customClass={styles.packageTitle}>
        {name}
      </Typography>

      <div className={styles.basketItem__wrapper}>
        <div className={styles.basketItem_rowWrapper}>
          <div className={styles.basketItem_rowDesc}>
            <Typography tag="span" customClass={styles.basketItem_name}>
              <Typography tag="span" isBold customClass={styles.basketItem_Count}>
                {count}x
              </Typography>{' '}
              {renderTransportLegs(transportItem)}
            </Typography>
            <Typography tag="span" customClass={styles.basketItem_price}>
              {itemTotal}
            </Typography>
          </div>
          <div className={styles.basketItem} />
          {mode === 'basket' && (
            <button
              data-testid="basket-delete-button"
              disabled={basketMutationStatus === 'pending'}
              className={styles.basketItem__deleteButton}
              onClick={() => removeTransportItem?.(transportItem)}
            >
              <DeleteIcon data-testid="basket-delete-icon-trash" />
            </button>
          )}
        </div>
      </div>
    </>
  );
};
