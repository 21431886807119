import { useEffect } from 'react';

const useUpdateToastWidth = () => {
  const updateToastWrapper = () => {
    // Obtain the engine container element
    const elementParent = document.querySelector('.engineContent') as HTMLElement | null;

    // Obtain any toast notification elements
    const notification = document.querySelector('.Toastify__toast-container') as HTMLElement | null;

    if (elementParent && notification) {
      const parentWidth = elementParent.offsetWidth;
      notification.style.width = `${parentWidth}px`;
    }
  };

  useEffect(() => {
    // Set up a MutationObserver to detect when the notification is added to the DOM
    const observer = new MutationObserver(() => {
      const notification = document.querySelector('.Toastify__toast-container');
      if (notification) {
        updateToastWrapper();
      }
    });

    // Start observing the document body for any added or removed nodes
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Attach resize event listener to update the width on screen resize
    window.addEventListener('resize', updateToastWrapper);

    // Clean up the observer and event listener when the component unmounts
    return () => {
      observer.disconnect();
      window.removeEventListener('resize', updateToastWrapper);
    };
  }, []);
};

export default useUpdateToastWidth;
