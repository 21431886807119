import React, { useCallback, useEffect, useMemo } from 'react';
import {
  generateBasketAccommodationItem,
  generateBasketExtrasItem,
  generateBasketTicketsItem,
  generateBasketTransportItem,
  removeAccommodationBasketItem,
  removeExtrasBasketItem,
  removeTicketsBasketItem,
  removeTransportBasketItem,
} from './BasketContent.helper';
import { useBasketMutation, useBasketQuery } from '@src/api/useBasket';

import BasketCartItem from './BasketCartItem';
import { DEVICE_TABLET_BREAKPOINT } from '@src/constants';
import styles from './basketContent.module.scss';
import { useBasketStore } from '@src/store/useBasketStore';
import useScreenBreakpoint from '@src/hooks/useScreenBreakpoint';
import { useTranslation } from 'react-i18next';
import {
  BasketAccommodationItem,
  type BasketExtraItem,
  type BasketTicketItem,
  BasketTransportItem,
} from './BasketContent.types';

export interface CartProps {
  isBasketOpen: boolean;
  basketMode?: 'basket' | 'checkout' | 'completed';
  testId?: string;
  toggleBasket?: () => void;
}

const BasketContent = ({
  isBasketOpen = false,
  basketMode = 'basket',
  testId = 'basketCart',
  toggleBasket,
}: CartProps) => {
  const { screenWidth } = useScreenBreakpoint();
  const { data: basketData } = useBasketQuery();
  const { mutateAsync } = useBasketMutation({ patch: true });
  const basketMutationStatus = useBasketStore((store) => store.mutateBasketStatus);
  const { t } = useTranslation();

  const isOnMobile = screenWidth < DEVICE_TABLET_BREAKPOINT;

  const basketTicketsItems = useMemo(
    () => generateBasketTicketsItem(basketData?.data?.tickets) ?? [],
    [basketData?.data?.tickets]
  );

  const basketExtrasItems = useMemo(
    () => generateBasketExtrasItem(basketData?.data?.extras),
    [basketData?.data?.extras]
  );

  const basketAccommodationItems = useMemo(
    () => generateBasketAccommodationItem(basketData?.data?.accommodations),
    [basketData?.data?.accommodations]
  );

  const basketTransportItems = useMemo(
    () => generateBasketTransportItem(basketData?.data?.transports),
    [basketData?.data?.transports]
  );

  const removeTicketFromBasket = useCallback(
    async (itemToRemove: BasketTicketItem) => removeTicketsBasketItem(itemToRemove, basketTicketsItems, mutateAsync, t),
    [basketTicketsItems, mutateAsync, t]
  );

  const removeExtraFromBasket = useCallback(
    async (itemToRemove: BasketExtraItem) => removeExtrasBasketItem(itemToRemove, basketExtrasItems, mutateAsync, t),
    [basketExtrasItems, mutateAsync, t]
  );

  const removeAccommodationFromBasket = useCallback(
    async (itemToRemove: BasketAccommodationItem, idx: number) =>
      removeAccommodationBasketItem(itemToRemove, basketAccommodationItems, mutateAsync, t, idx),
    [basketAccommodationItems, mutateAsync, t]
  );

  const removeTransportFromBasket = useCallback(
    async (itemToRemove: BasketTransportItem) =>
      removeTransportBasketItem(itemToRemove, basketTransportItems, mutateAsync, t),
    [basketTransportItems, mutateAsync, t]
  );

  // Handle prevent scrolling when basket cart is open on mobile only.
  useEffect(() => {
    if (basketMode === 'basket') {
      if (isOnMobile && isBasketOpen) {
        document.body.classList.toggle(styles.preventScroll);
      }
      return () => {
        document.body.classList.remove(styles.preventScroll);
      };
    }
  }, [isOnMobile, basketMode, isBasketOpen]);

  return (
    <div className={styles.basketContent} data-testid={`${testId}-contents`}>
      {Boolean(basketTicketsItems.length) && (
        <div className={styles.cartItemCategory}>
          {basketTicketsItems?.map((item, index) => (
            <BasketCartItem
              basketMutationStatus={basketMutationStatus}
              removeTicketItem={removeTicketFromBasket}
              key={`${item.name}_${item.typeId}_${index}`}
              mode={basketMode}
              isfirstItem={index === 0}
              engine="tickets"
              ticketItem={item}
              toggleBasket={toggleBasket}
            />
          ))}
          {(Boolean(basketExtrasItems.length > 0) ||
            Boolean(basketAccommodationItems.length > 0) ||
            Boolean(basketTransportItems.length > 0)) && <hr />}
        </div>
      )}

      {Boolean(basketExtrasItems.length) && (
        <>
          <div className={styles.cartItemCategory}>
            {basketExtrasItems?.map((item, index) => (
              <BasketCartItem
                basketMutationStatus={basketMutationStatus}
                removeExtrasItem={removeExtraFromBasket}
                key={`${item.extraName}_${item.extraId}_${index}`}
                mode={basketMode}
                isfirstItem={index === 0}
                engine="extras"
                extrasItem={item}
                toggleBasket={toggleBasket}
              />
            ))}
            {Boolean(basketAccommodationItems.length > 0 || Boolean(basketTransportItems.length > 0)) && <hr />}
          </div>
        </>
      )}

      {Boolean(basketTransportItems.length) && (
        <div className={styles.cartItemCategory}>
          {basketTransportItems?.map((item, index) => (
            <BasketCartItem
              basketMutationStatus={basketMutationStatus}
              key={`${item.routeId}_${index}`}
              mode={basketMode}
              isfirstItem={index === 0}
              engine="transport"
              transportItem={item}
              toggleBasket={toggleBasket}
              removeTransportItem={removeTransportFromBasket}
            />
          ))}
          {Boolean(basketAccommodationItems.length > 0) && <hr />}
        </div>
      )}

      {Boolean(basketAccommodationItems.length) && (
        <div className={styles.cartItemCategory}>
          {basketAccommodationItems?.map((item, index) => (
            <BasketCartItem
              accommodationItem={item}
              basketMutationStatus={basketMutationStatus}
              removeAccommodationItem={(itemToRemove) => removeAccommodationFromBasket(itemToRemove, index)}
              key={`${item.ruleId}_${index}`}
              mode={basketMode}
              isfirstItem={index === 0}
              engine="accommodation"
              toggleBasket={toggleBasket}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default BasketContent;
