import { useEffect } from 'react';
import { useLoadingStore } from '@src/store/useLoadingStore';

export const useCloseKaboodleLogoOnMount = () => {
  const { setIsAppInitializing } = useLoadingStore();
  useEffect(() => {
    if (setIsAppInitializing) {
      setIsAppInitializing(false);
    }
  }, [setIsAppInitializing]);
};
