import { useEffect } from 'react';

const useBasketHeightAdjustment = (isOnMobile: boolean, isExpanded: boolean) => {
  const updateBasketWrapperHeight = () => {
    const basketWrapper = document.querySelector('.basketCartOpen') as HTMLElement | null;
    const basketWrapperInner = document.querySelector('.basketWrapper') as HTMLElement | null;
    const basketContent = document.querySelector('.basketContent') as HTMLElement | null;
    const basketFooter = document.querySelector('.basketContainer') as HTMLElement | null;
    const basketHeader = document.querySelector('.basketHeaderWrapper') as HTMLElement | null;

    const viewportHeight = window.innerHeight;

    // Proceed only if all required elements are found
    if (basketHeader && basketFooter && basketWrapper && basketWrapperInner && basketContent) {
      const headerHeight = basketHeader.offsetHeight;
      const footerHeight = basketFooter.offsetHeight;

      // Calculate available height for the content
      const availableHeight = viewportHeight - (headerHeight + footerHeight);
      const doubleFooter = footerHeight * 2;
      const overallContentHeight = availableHeight - doubleFooter;

      let totalHeight = 0;

      Array.from(basketContent.children).forEach((child) => {
        totalHeight += (child as HTMLElement).offsetHeight;
      });

      // Set max-height of basketWrapper and basketContent based on calculations
      basketWrapper.style.maxHeight = `${viewportHeight - footerHeight}px`;
      basketContent.style.maxHeight = `${overallContentHeight}px`;
      basketContent.style.height = `${totalHeight}px`;
    }
  };

  useEffect(() => {
    if (!isOnMobile) return;

    // Initial calculation on page load
    updateBasketWrapperHeight();

    // Check if basketContent exists and observe it
    const basketContent = document.querySelector('.basketContent') as HTMLElement | null;

    if (basketContent) {
      // MutationObserver to observe changes in the child elements of basketContent
      const observer = new MutationObserver(() => {
        updateBasketWrapperHeight();
      });

      observer.observe(basketContent, {
        childList: true, // Monitor when children are added or removed
        subtree: true, // Observe changes in all descendants
        attributes: true, // Trigger when attributes change
        characterData: true, // Detect when text inside the child nodes changes
      });

      return () => {
        observer.disconnect();
      };
    }
  }, [isOnMobile, isExpanded]);

  // Attach the event listener for 'resize'.
  useEffect(() => {
    window.addEventListener('resize', updateBasketWrapperHeight);

    return () => {
      window.removeEventListener('resize', updateBasketWrapperHeight);
    };
  }, []);
};

export default useBasketHeightAdjustment;
