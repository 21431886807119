function getEnvironmentFromEnvironment(): string {
  switch (process.env.ENVIRONMENT) {
    case 'dev':
    case 'development':
      return 'development';
    case 'production':
      return 'production';
    case 'staging':
      return 'staging';
    case 'testing':
      return 'testing';
    default:
      throw new Error('Not a known or valid environment');
  }
}

export { getEnvironmentFromEnvironment };
