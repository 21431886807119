import * as Sentry from '@sentry/react';

import { QK_FIREBASE_ANON_SIGN_IN, QK_SHOW_KABOODLE_LOADING } from '@src/api/queryKeys';
import { browserSessionPersistence, getAuth, setPersistence } from 'firebase/auth';
import { handleFirebaseAnonymousLogin, useFirebaseUserIdToken } from './useFirebaseAuth.helper';
import { useCallback, useEffect } from 'react';

import { useAxiosInterceptors } from '../useAxiosInterceptors';
import useBpTokenStore from '@src/store/useBpTokenStore';
import { useQuery } from '@tanstack/react-query';

const useFirebaseAuth = (tenantId: string | undefined, enableAuthStart = true) => {
  const { _hasBpTokenStoreHydrated, bpTokenExtractedUnverified } = useBpTokenStore();
  const auth = getAuth();

  // Configure auth persistence
  useEffect(() => {
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        console.log('Auth persistence set');
      })
      .catch((error) => {
        // Report to sentry persistence errors
        Sentry.captureException(error);
      });
  }, [auth]);

  const signOutOldTenantUser = useCallback(async () => {
    if (enableAuthStart && tenantId && tenantId !== auth.tenantId) {
      auth.tenantId = tenantId;
    }

    if (
      enableAuthStart &&
      _hasBpTokenStoreHydrated &&
      tenantId &&
      bpTokenExtractedUnverified?.t &&
      tenantId !== bpTokenExtractedUnverified.t
    ) {
      await auth.signOut();
    }
  }, [tenantId, bpTokenExtractedUnverified?.t, _hasBpTokenStoreHydrated, auth, enableAuthStart]);
  signOutOldTenantUser();

  const {
    data: anonSignInResponse,
    isLoading: isFireBaseAnonSignInLoading,
    isSuccess: isFireBaseAnonSignInSuccessful,
    ...rest
  } = useQuery({
    queryKey: [QK_SHOW_KABOODLE_LOADING, QK_FIREBASE_ANON_SIGN_IN],
    queryFn: () => handleFirebaseAnonymousLogin(auth),
    enabled: enableAuthStart && !!auth.tenantId && !!tenantId && tenantId === auth.tenantId,
    retry: 5,
  });

  const { userIdToken } = useFirebaseUserIdToken(auth.currentUser, isFireBaseAnonSignInSuccessful);
  const { isFirstBearerTokenHeaderReady, isFirstBpTokenHeaderReady } = useAxiosInterceptors(userIdToken);

  return {
    response: anonSignInResponse,
    ...rest,
    isFireBaseAnonSignInLoading,
    isFireBaseAnonSignInSuccessful,
    isFirebaseAuthReady: isFireBaseAnonSignInSuccessful && isFirstBearerTokenHeaderReady,
    isFirebaseAuthLoading: isFireBaseAnonSignInLoading || !isFirstBearerTokenHeaderReady,
    isFirstBpTokenHeaderReady,
    userIdToken,
  };
};

export { useFirebaseAuth };
