import type { AnySchema, InferType } from 'yup';

/**
 * Decode the payload from a JWT. Does **NOT** verify. That must be done server-side.
 *
 * https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
 */
export const extractUnverifiedJwtPayload = (token: string, shape?: AnySchema) => {
  try {
    const tokenSplit = token.split('.');

    if (tokenSplit.length !== 3) {
      throw new Error('Invalid Token Format');
    }

    const base64Url = tokenSplit[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    // TODO - Add a typecheck here before returning the parsed token that will compare
    // the shape of parsedToken with the expected shape of the token, and then return
    // an error if they don't match
    const parsedToken = JSON.parse(jsonPayload);

    if (shape) {
      type Shape = InferType<typeof shape>;

      const isValid = shape.validateSync(parsedToken);
      if (!isValid) {
        throw new Error('Invalid Token Format');
      }

      return parsedToken as Shape;
    }

    return parsedToken;
  } catch (error) {
    let errorMessage = 'Failed to decode token';

    if (error && typeof error === 'object' && 'message' in error) {
      errorMessage = String(error.message);
    }

    throw new Error(errorMessage);
  }
};
