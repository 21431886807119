import {
  BasketAccommodationItem,
  type BasketExtraItem,
} from '@src/components/Basket/BasketContent/BasketContent.types';

export const isBasketExtrasItem = (object: unknown): object is BasketExtraItem => {
  if (
    (object as BasketExtraItem)?.extraId &&
    (object as BasketExtraItem)?.groupId &&
    (object as BasketExtraItem)?.optionId
  ) {
    return true;
  }

  return false;
};

export const isBasketAccommodationItem = (object: unknown): object is BasketAccommodationItem => {
  return !!(
    (object as BasketAccommodationItem)?.ruleId &&
    (object as BasketAccommodationItem)?.accommodationId &&
    (object as BasketAccommodationItem)?.roomId
  );
};
