import {
  BasketAccommodationItem,
  BasketExtraItem,
  BasketItem,
  BasketItemVariableProps,
  BasketTicketItem,
  BasketTransportItem,
  ItemConfig,
  ItemType,
} from './BasketContent.types';

export function isBasketTicketItem(item: BasketItem): item is BasketTicketItem {
  return 'typeId' in item && !('extraName' in item) && !('accommodationName' in item);
}

export function isBasketExtraItem(item: BasketItem): item is BasketExtraItem {
  return 'extraName' in item && 'optionName' in item;
}

export function isBasketAccommodationItem(item: BasketItem): item is BasketAccommodationItem {
  return 'accommodationName' in item && 'roomName' in item;
}

export function isBasketTransportItem(item: BasketItem): item is BasketTransportItem {
  return 'routeId' in item;
}

const itemConfigs: Record<ItemType, ItemConfig> = {
  accommodation: {
    itemKey: 'accommodationItem',
    removeKey: 'removeAccommodationItem',
    getItem: (props): BasketAccommodationItem | null => props.accommodationItem ?? null,
    getRemoveFunction: (props) => {
      if (props.removeAccommodationItem) {
        return (item: BasketItem) => {
          if (isBasketAccommodationItem(item) && props.removeAccommodationItem) {
            return props.removeAccommodationItem(item);
          }
          return Promise.reject(new Error('Invalid item type or remove function not found'));
        };
      }
      return null;
    },
    getProperties: (item: BasketItem) => {
      if (isBasketAccommodationItem(item)) {
        return {
          count: item.count,
          holders: null,
          name: item.roomName,
          price: { value: item.price.value, bookingFee: item.price.bookingFee },
          irremovable: false,
        };
      }
      throw new Error('Invalid item type');
    },
  },
  extras: {
    itemKey: 'extrasItem',
    removeKey: 'removeExtrasItem',
    getItem: (props): BasketExtraItem | null => props.extrasItem ?? null,
    getRemoveFunction: (props) => {
      if (props.removeExtrasItem) {
        return (item: BasketItem) => {
          if (isBasketExtraItem(item) && props.removeExtrasItem) {
            return props.removeExtrasItem(item);
          }
          return Promise.reject(new Error('Invalid item type or remove function not found'));
        };
      }
      return null;
    },
    getProperties: (item: BasketItem) => {
      if (isBasketExtraItem(item)) {
        return {
          count: item.count,
          holders: null,
          name: `${item.extraName} - ${item.optionName}`,
          price: { value: item.price.value, bookingFee: item.price.bookingFee },
          irremovable: item.irremovable ?? false,
        };
      }
      throw new Error('Invalid item type');
    },
  },
  ticket: {
    itemKey: 'ticketItem',
    removeKey: 'removeTicketItem',
    getItem: (props): BasketTicketItem | null => props.ticketItem ?? null,
    getRemoveFunction: (props) => {
      if (props.removeTicketItem) {
        return (item: BasketItem) => {
          if (isBasketTicketItem(item) && props.removeTicketItem) {
            return props.removeTicketItem(item);
          }
          return Promise.reject(new Error('Invalid item type or remove function not found'));
        };
      }
      return null;
    },
    getProperties: (item: BasketItem) => {
      if (isBasketTicketItem(item)) {
        return {
          count: item.count,
          holders: item.holders,
          name: item.name,
          price: item.price,
          irremovable: item.irremovable ?? false,
        };
      }
      throw new Error('Invalid item type');
    },
  },
  transport: {
    itemKey: 'transportItem',
    removeKey: 'removeTransportItem',
    getItem: (props): BasketTransportItem | null => props.transportItem ?? null,
    getRemoveFunction: (props) => {
      if (props.removeTransportItem) {
        return (item: BasketItem) => {
          if (isBasketTransportItem(item) && props.removeTransportItem) {
            return props.removeTransportItem(item);
          }
          return Promise.reject(new Error('Invalid item type or remove function not found'));
        };
      }
      return null;
    },
    getProperties: (item: BasketItem) => {
      if (isBasketTransportItem(item)) {
        const totalPrice = item.legs.reduce((acc, leg) => {
          return acc + (leg.price?.value || 0);
        }, 0);

        const totalBookingFee = item.legs.reduce((acc, leg) => {
          return acc + (leg.price?.bookingFee || 0);
        }, 0);
        return {
          count: item.count || 1,
          holders: null,
          name: item.routeName || '',
          price: { value: totalPrice, bookingFee: totalBookingFee },
          irremovable: false,
        };
      }
      throw new Error('Invalid item type');
    },
  },
};

export const getItemAndRemoveFunctionFromCartItemProps = (otherProps: BasketItemVariableProps) => {
  const itemType = (Object.keys(itemConfigs) as ItemType[]).find((key) => itemConfigs[key].itemKey in otherProps);

  if (!itemType) {
    throw new Error('Invalid item type in props');
  }

  const config = itemConfigs[itemType];
  const item = config.getItem(otherProps);
  const removeItemFunc = config.getRemoveFunction(otherProps);

  if (!item || !removeItemFunc) {
    throw new Error('Item or remove function not found');
  }

  const removeItem = () => removeItemFunc(item);

  return { item, removeItem };
};

export const getPropertiesFromCartItem = (item: BasketItem) => {
  if (isBasketAccommodationItem(item)) {
    return itemConfigs.accommodation.getProperties(item);
  } else if (isBasketExtraItem(item)) {
    return itemConfigs.extras.getProperties(item);
  } else if (isBasketTicketItem(item)) {
    return itemConfigs.ticket.getProperties(item);
  } else if (isBasketTransportItem(item)) {
    return itemConfigs.transport.getProperties(item);
  }
  throw new Error('Invalid item type');
};
