import { HOME_ROUTE, RESTART_ROUTE } from '@src/constants';

import { MutableRefObject } from 'react';
import { findFirstEngine } from '@src/lib/findFirstEngine';
import { useErrorBoundary } from '@src/components/ErrorBoundary';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { useLocation } from 'react-router-dom';
import { useNavigateWithQueryParams } from '@src/hooks/useNavigateWithQueryParams/useNavigateWithQueryParams';

type Props = {
  isFirstBpTokenHeaderReady: boolean;
  isFirebaseAuthReady: boolean;
  isFirstLoad: MutableRefObject<boolean>;
  isInitLoading: boolean;
};

/**
 * On success of the init process and firebase authentication we want to redirect the user to the first engine.
 * If the user has a current session, we don't want to redirect.
 * If we can't find any first engines to redirect it's a init failure.
 */
export const useHandleRedirectAfterInit = ({
  isFirstLoad,
  isFirstBpTokenHeaderReady,
  isFirebaseAuthReady,
  isInitLoading,
}: Props) => {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { engines } = useInitResponseStore();
  const location = useLocation();

  const { showBoundary } = useErrorBoundary();
  const isHomeRoute = location.pathname === HOME_ROUTE;
  const isRestartRoute = location.pathname === RESTART_ROUTE;

  if (
    (isHomeRoute || isRestartRoute) &&
    isFirstLoad.current &&
    !isInitLoading &&
    isFirstBpTokenHeaderReady &&
    isFirebaseAuthReady
  ) {
    const firstEngineRoute = findFirstEngine(engines)?.route;

    if (firstEngineRoute) {
      navigateWithQueryParams(firstEngineRoute);
      isFirstLoad.current = false;
      return;
    }

    if (!firstEngineRoute) {
      // If failing to find an initial engine, set appLoading to false
      // to hide the `LoadingSVG`
      showBoundary({ template: 'initFailure' });
      isFirstLoad.current = false;
      return;
    }
  }
};
