import axios from 'axios';
// import { mockedExtras } from './mockedExtras';

export const mockedApi = axios.create({
  baseURL: process.env.MOCK_URL,
});

export const BPAPI = axios.create({
  baseURL: `${process.env.BPAPI_URL}/v${process.env.BPAPI_VERSION}`,
});

// export const getExtras = async () => {
//   await new Promise((resolve) => setTimeout(resolve, 4000));
//   return mockedExtras.extras;
// };
