import { ReactNode } from 'react';
import { create } from 'zustand';

interface ModalState {
  status: 'open' | 'closed';
  content: ReactNode | null;
  canBeClosed: boolean;
  setHasCloseButton: (canBeClosedNewValue: boolean) => void;
  closeModal: () => void;
  openModal: (content: ReactNode, canBeClosed?: boolean) => void;
  toggleModal: (content: ReactNode, canBeClosed?: boolean) => void;
}

const disableScroll = (value: boolean) => (document.body.style.overflow = value ? 'hidden' : 'auto');

/**
 * Zustand store for modal state. Configure the status to determine
 * if the modal is open or closed. The modal content is the content
 * that will be displayed in the modal. The canBeClosed value
 * determines if the modal can be closed by clicking outside of it and
 * whether a close button is rendered.
 */
export const useModalStore = create<ModalState>()((set) => ({
  status: 'closed',
  content: null,
  canBeClosed: true,
  setHasCloseButton: (canBeClosedNewValue) => set(() => ({ canBeClosed: canBeClosedNewValue })),
  closeModal: () => set(() => ({ status: 'closed', content: null })),
  openModal: (newContent, canBeClosedNewValue) =>
    set((state) => ({
      content: newContent,
      status: 'open',
      canBeClosed: canBeClosedNewValue === undefined ? state.canBeClosed : canBeClosedNewValue,
    })),
  toggleModal: (newContent, canBeClosedNewValue) =>
    set((state) => ({
      content: newContent,
      status: state.status === 'open' ? 'closed' : 'open',
      canBeClosed: canBeClosedNewValue === undefined ? state.canBeClosed : canBeClosedNewValue,
    })),
}));

useModalStore.subscribe((state) => {
  if (state.status === 'open') {
    disableScroll(true);
  } else {
    disableScroll(false);
  }
});
