import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AiOutlineLoading } from 'react-icons/ai';
import { useNavigateWithQueryParams } from '@src/hooks/useNavigateWithQueryParams/useNavigateWithQueryParams';

import { Typography } from '@kaboodle-solutions/design-library';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter/useCurrencyFormatter';
import { type BasketStoreState } from '@src/store/useBasketStore';

import DeleteIcon from '../../../styles/svg/delete-icon.svg?react';
import {
  getItemAndRemoveFunctionFromCartItemProps,
  getPropertiesFromCartItem,
  isBasketAccommodationItem,
  isBasketExtraItem,
  isBasketTicketItem,
  isBasketTransportItem,
} from './BasketCartItem.helper';
// import eCommTracking from '@src/lib/tracking/eCommTracking';
import { calculateLineTotalByCount } from './BasketContent.helper';
import styles from './basketContent.module.scss';
import {
  BasketAccommodationItem,
  type BasketExtraItem,
  type BasketTicketItem,
  BasketTransportItem,
} from './BasketContent.types';
import { TransportCartItem } from '@components/Basket/BasketContent/CartItems/TransportCartItem';

// The basket item can be either tickets or extras
export type BasketItemVariableProps =
  | {
      ticketItem: BasketTicketItem;
      removeTicketItem: (itemToRemove: BasketTicketItem) => Promise<void>;
    }
  | {
      extrasItem: BasketExtraItem;
      removeExtrasItem: (itemToRemove: BasketExtraItem) => Promise<void>;
    }
  | {
      accommodationItem: BasketAccommodationItem;
      removeAccommodationItem: (itemToRemove: BasketAccommodationItem) => Promise<void>;
    }
  | {
      transportItem: BasketTransportItem;
      removeTransportItem: (itemToRemove: BasketTransportItem) => Promise<void>;
    };

export type BasketItemProps = {
  engine: string;
  isfirstItem: boolean;
  mode?: 'basket' | 'checkout' | 'completed';
  toggleBasket?: () => void;
  basketMutationStatus: BasketStoreState['mutateBasketStatus'];
} & BasketItemVariableProps;

const BasketCartItem = ({
  engine,
  isfirstItem,
  mode,
  basketMutationStatus,
  toggleBasket,
  ...variableProps
}: BasketItemProps) => {
  const [isItemBeingRemoved, setIsItemBeingRemoved] = useState<boolean>(false);

  const { item, removeItem } = getItemAndRemoveFunctionFromCartItemProps(variableProps);
  const { count, holders, name, price, irremovable } = getPropertiesFromCartItem(item);

  const { format } = useCurrencyFormatter();
  const { t } = useTranslation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const accommHasExtras = typeof item === 'object' && 'ruleId' in item && item.extras.length > 0;

  const onEngineNameClick = () => {
    if (toggleBasket) {
      toggleBasket();
    }

    navigateWithQueryParams(`/${engine}`);
  };

  const handleRemoveItem = useCallback(async () => {
    const eCommTracking = (await import('@src/lib/tracking/eCommTracking')).default;

    setIsItemBeingRemoved(true);
    await removeItem().finally(() => {
      setIsItemBeingRemoved(false);
      if (isBasketExtraItem(item)) {
        eCommTracking.extras.sendRemoveFromCartEvent({ ...item, count });
      } else if (isBasketAccommodationItem(item)) {
        eCommTracking.accomm.sendRemoveFromCartEvent({ ...item, count });
      } else if (isBasketTicketItem(item)) {
        eCommTracking.tickets.sendRemoveFromCartEvent({ ...item, count });
      } else if (isBasketTransportItem(item)) {
        // TODO: Enable this when we have a transport tracking event
        // eCommTracking.transport.sendRemoveFromCartEvent({ ...item, count });
      }
    });
  }, [removeItem, item, count]);

  const itemTotal = useMemo(
    () => (price?.value ? format(calculateLineTotalByCount({ count, price: price?.value })) : null),
    [format, count, price]
  );
  const bookingFeeTotal = useMemo(
    () => (price.bookingFee ? format(calculateLineTotalByCount({ count, price: price.bookingFee }), true) : null),
    [format, count, price]
  );

  const getEngineTitle = (engine: string): string => {
    type TranslationKeys =
      | 'ticketsEngine:title'
      | 'extrasEngine:title'
      | 'accommodationEngine:title'
      | 'transportEngine:title';

    const engineTitleMap: { [key: string]: TranslationKeys } = {
      tickets: 'ticketsEngine:title',
      extras: 'extrasEngine:title',
      accommodation: 'accommodationEngine:title',
      transport: 'transportEngine:title',
    };

    // Get the translation key or fallback to the default
    const translationKey: TranslationKeys = engineTitleMap[engine as keyof typeof engineTitleMap];
    return t(translationKey) as string;
  };

  if ('transportItem' in variableProps) {
    return (
      <TransportCartItem
        transportItem={variableProps.transportItem}
        isfirstItem={isfirstItem}
        mode={mode}
        engine={engine}
        getEngineTitle={getEngineTitle}
        onEngineNameClick={onEngineNameClick}
        basketMutationStatus={basketMutationStatus}
        removeTransportItem={handleRemoveItem}
      />
    );
  }

  return (
    <>
      {/* Display engine name */}
      {/* if mode is basket, display as a link */}
      {isfirstItem && mode === 'basket' && (
        <button
          id="basket-cart-engine-name-link"
          className={styles.basketItemEngineNameLink}
          onClick={onEngineNameClick}
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onEngineNameClick();
            }
          }}
        >
          <Typography tag="span" customClass={styles.basketItemEngineNameLinkText}>
            {getEngineTitle(engine)}
          </Typography>
        </button>
      )}
      {isfirstItem && mode === 'checkout' && (
        <Typography tag="span" isBold customClass={styles.basketItemEngineName}>
          {getEngineTitle(engine)}
        </Typography>
      )}
      {'showAccommName' in item && item.showAccommName && (
        <Typography tag="span" isBold customClass={styles.packageTitle}>
          {'accommodationName' in item ? item?.accommodationName : ''}
        </Typography>
      )}

      <div className={styles.basketItem__wrapper}>
        <div className={styles.basketItem_rowWrapper}>
          <div className={styles.basketItem}>
            {/* Display ticket name and price */}
            <div className={styles.basketItem_rowDesc}>
              <Typography tag="span" customClass={styles.basketItem_name}>
                <Typography tag="span" isBold customClass={styles.basketItem_Count}>
                  {count}x
                </Typography>{' '}
                {name}
              </Typography>
              <Typography tag="span" customClass={styles.basketItem_price}>
                {itemTotal}
              </Typography>
            </div>

            {/*  Display booking fee if higher then 0 */}
            {Boolean(bookingFeeTotal) && (
              <div className={styles.basketItem_rowBookingFee}>
                <Typography tag="span" customClass={styles.basketItem_bookingFee}>
                  {t('common:bookingFee')}
                </Typography>
                <Typography tag="span" customClass={styles.basketItem_bookingFeePrice}>
                  {bookingFeeTotal}
                </Typography>
              </div>
            )}

            {/* Display ticket holder name & ticket count */}
            {!!holders &&
              holders
                .filter(({ firstName, lastName }) => firstName?.trim() !== '' || lastName?.trim() !== '')
                .map(({ firstName, lastName }, idx) => (
                  <div key={`${idx}`} className={styles.basketItem_rowCustomerName}>
                    <Typography tag="span" type="subtext">
                      <span className={styles.basketItem_Count}>1x</span>
                    </Typography>
                    <Typography tag="span" type="subtext" customClass={styles.basketItem_Customer}>
                      {`${' ' + firstName + ' ' + lastName + ' '}`}
                    </Typography>
                  </div>
                ))}
          </div>
          {mode === 'basket' ? (
            !irremovable ? (
              <button
                data-testid="basket-delete-button"
                disabled={basketMutationStatus === 'pending'}
                className={styles.basketItem__deleteButton}
                onClick={handleRemoveItem}
              >
                {isItemBeingRemoved ? (
                  <AiOutlineLoading
                    data-testid="basket-delete-icon-spinner"
                    className={styles.basketItem__spinnerIcon}
                  />
                ) : (
                  <DeleteIcon data-testid="basket-delete-icon-trash" />
                )}
              </button>
            ) : null
          ) : null}
        </div>
        {accommHasExtras && (
          <div className={styles.basketItem__extrasWrapper}>
            {
              <Typography tag="span" isBold customClass={styles.basketExtrasName}>
                {t('accommodationEngine:titleWithExtras')}
              </Typography>
            }
            {item.extras.map((extra, index) => (
              <div key={`${extra.optionId}-${index}`} className={styles.basketItem}>
                <div className={styles.basketItem_rowDesc}>
                  <Typography tag="span" customClass={styles.basketItem_name}>
                    <Typography tag="span" isBold customClass={styles.basketItem_Count}>
                      {extra.count}x
                    </Typography>{' '}
                    {extra.optionName}
                  </Typography>
                  <Typography tag="span" customClass={styles.basketItem_price}>
                    {format(extra.price?.value * extra.count)}
                  </Typography>
                </div>
                <div className={styles.basketItem_rowBookingFee}>
                  <Typography tag="span" customClass={styles.basketItem_bookingFee}>
                    {t('common:bookingFee')}
                  </Typography>
                  <Typography tag="span" customClass={styles.basketItem_bookingFeePrice}>
                    {format(extra.price?.bookingFee * extra.count, true) || 0}
                  </Typography>
                </div>
                {!!extra.textInput && extra?.textInput?.length > 0 && (
                  <div className={styles.basketItem_rowBookingFee}>
                    <Typography tag="span" customClass={styles.basketItem_bookingFee}>
                      {extra.textInput}
                    </Typography>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default BasketCartItem;
