import { Typography } from '@kaboodle-solutions/design-library';
import { useBasketExpiryTimer } from '@src/hooks/useBasketExpiryTimer/useBasketExpiryTimer';

interface Props {
  onTimerExpired?: () => void;
  customClass: string;
}

const CountdownTimer = ({ customClass, onTimerExpired }: Props) => {
  const remainingTimerInSeconds = useBasketExpiryTimer(onTimerExpired);

  const remainingHours = Math.floor(remainingTimerInSeconds / (60 * 60))
    .toString()
    .padStart(2, '0');

  const remainingMinutes = Math.floor(remainingTimerInSeconds / 60 - Number(remainingHours) * 60)
    .toString()
    .padStart(2, '0');

  const remainingSeconds = Math.floor(
    remainingTimerInSeconds - Number(remainingMinutes) * 60 - Number(remainingHours) * 60 * 60
  )
    .toString()
    .padStart(2, '0');

  return (
    <Typography tag="span" type="caption" isBold customClass={customClass}>
      {`${Number(remainingHours) > 0 ? `${remainingHours}:` : ''}${remainingMinutes}:${remainingSeconds}`}
    </Typography>
  );
};

export default CountdownTimer;
