import React, { forwardRef, useEffect, useRef } from 'react';

import { KABOODLE_CDN } from '@src/constants/constants';
import { Typography } from '@kaboodle-solutions/design-library';
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';

export interface FooterProps {
  ShowKlarnaFAQs?: boolean;
}

const Footer = forwardRef<HTMLDivElement, FooterProps>(({ ShowKlarnaFAQs }, ref) => {
  const { t } = useTranslation('footer');
  const imgRef = useRef<HTMLImageElement>(null);
  const poweredByRef = useRef<HTMLImageElement>(null);

  const poweredByLogoToken = '--footer-powered-by';
  const starLogoToken = '--footer-star';

  /**
   * Function to update the image source using the CSS variable
   */
  const updateImageSrc = (token: string, imgElement: React.RefObject<HTMLImageElement>) => {
    if (imgElement.current) {
      const bodyElement = document.body;
      const computedStyle = getComputedStyle(bodyElement);
      const logoUrl = computedStyle.getPropertyValue(token).trim();

      if (logoUrl) {
        let extractedUrl = logoUrl;

        // Check if the URL is wrapped in url('...')
        const urlMatch = logoUrl.match(/url\(['"]?([^'"]+)['"]?\)/);
        if (urlMatch) {
          extractedUrl = urlMatch[1];
        }

        // Remove single quotes if present
        extractedUrl = extractedUrl.replace(/^['"]+|['"]+$/g, '');
        imgElement.current.src = extractedUrl;
      }
    }
  };

  useEffect(() => {
    const retryInterval = 100; // Time between retries in milliseconds
    const maxRetries = 5; // Maximum number of retries
    let retryCount = 0; // Counter for the number of retries

    /**
     * Function to check and update the image source.
     * Returns true if the CSS variable is found and the image source is updated, otherwise false.
     */
    const checkAndUpdateImageSrc = () => {
      const computedStyle = getComputedStyle(document.body);
      const footerStarUrl = computedStyle.getPropertyValue(starLogoToken).trim();
      const poweredByLogoUrl = computedStyle.getPropertyValue(poweredByLogoToken).trim();

      if (footerStarUrl && poweredByLogoUrl) {
        updateImageSrc(starLogoToken, imgRef); // Update the star logo image source if the CSS variable is found
        updateImageSrc(poweredByLogoToken, poweredByRef); // Update the powered by logo image source if the CSS variable is found
        return true;
      }
      return false;
    };

    // Interval to periodically check for the CSS variable and update the image source
    const intervalId = setInterval(() => {
      if (checkAndUpdateImageSrc() || retryCount >= maxRetries) {
        clearInterval(intervalId); // Clear the interval if the variable is found or max retries are reached
      }
      retryCount += 1; // Increment the retry count
    }, retryInterval);

    // Observer to watch for changes in the body's class attribute
    const observer = new MutationObserver(() => {
      if (checkAndUpdateImageSrc()) {
        clearInterval(intervalId); // Clear the interval if the variable is found
      }
    });

    // Start observing the body element for attribute changes
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    // Cleanup function to disconnect the observer and clear the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
      observer.disconnect();
    };
  }, []);

  const links = [
    {
      href: `${KABOODLE_CDN}/KB/terms/terms_and_conditions.pdf`,
      title: t('kaboodleTermsConditions'),
      text: t('kaboodleTermsConditions'),
    },
    {
      href: `https://kaboodle.com/privacy-cookies`,
      title: t('privacyCookieStatement'),
      text: t('privacyCookieStatement'),
    },
    ...(ShowKlarnaFAQs
      ? [
          {
            href: 'https://kaboodle.co.uk/klarna',
            title: t('klarnaFaqs'),
            text: t('klarnaFaqs'),
            dataTest: 'klarna-faqs',
          },
        ]
      : []),
    {
      href: 'https://help.kaboodle.com/',
      title: t('faqs'),
      text: t('faqs'),
    },
  ];

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footer} ref={ref}>
        <div className={styles.logoContainer}>
          <Typography tag="span" type="caption" customClass={styles.poweredByText}>
            {t('poweredBy')}
          </Typography>
          <img ref={poweredByRef} className={styles.poweredByLogo} alt={t('poweredBy')} />
        </div>
        <nav id="footer-nav" className="footer-nav">
          <ul>
            {links.map(({ href, title, text, dataTest }) => (
              <li key={href}>
                <a
                  className={styles.footerLink}
                  target="_blank"
                  tabIndex={0}
                  href={href}
                  title={title || ''}
                  rel="noreferrer"
                  data-test={dataTest}
                >
                  <Typography customClass={styles.footerLink} tag="span">
                    {text}
                  </Typography>
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <img ref={imgRef} className={styles.stfar_logo} alt={t('starLogoAlt')} />
      </div>
    </footer>
  );
});

Footer.displayName = 'Footer';
export default Footer;
