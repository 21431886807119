import { create } from 'zustand';

interface AppearanceState {
  displayShellComponents: boolean;
  setDisplayShellComponents: (newValue: boolean) => void;
}

export const useAppearanceStore = create<AppearanceState>()((set) => ({
  // State
  displayShellComponents: true,

  // Action
  setDisplayShellComponents: (newValue: boolean) => set(() => ({ displayShellComponents: newValue })),
}));
