import { QK_GET_PAYMENT_OPTIONS, QK_SHOW_3_DOTS_LOADING } from './queryKeys';

import { BPAPI } from './mockedApi';
import { PaymentOption } from '@src/interfaces/paymentOption';
import { useQuery } from '@tanstack/react-query';

const getPaymentOptions = async () => {
  const { data } = await BPAPI.get<PaymentOption[]>(`/payment/payment-options`);

  return data;
};

export const useGetPaymentOptions = (enabled = true) =>
  useQuery({
    queryKey: [QK_SHOW_3_DOTS_LOADING, QK_GET_PAYMENT_OPTIONS],
    queryFn: async () => {
      return getPaymentOptions();
    },
    enabled,
    staleTime: Infinity,
  });

export type { PaymentOption };
