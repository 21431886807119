import { BPAPI } from './mockedApi';
import { PaymentIntentResponse, PutPaymentIntentPayload } from '@src/interfaces/paymentIntent';

export const getPaymentIntent = async () => {
  const { data } = await BPAPI.get<PaymentIntentResponse | null>(`/payment/intent`);
  return data;
};

export const getPaymentIntentStatus = async () => await BPAPI.get<PaymentIntentResponse>(`/payment/status`);

export const putPaymentIntent = async (payload: PutPaymentIntentPayload) => {
  const { data } = await BPAPI.put<PaymentIntentResponse>('/payment/intent', payload);
  return data;
};
