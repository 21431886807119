import { createJSONStorage, persist } from 'zustand/middleware';

import { PaymentTypeHandle } from '@src/constants';
import { create } from 'zustand';
import customStorageDefinition from '../customStorageDefinition';

interface PaymentMethodState {
  _hasPaymentMethodStoreHydrated: boolean;
  setHasHydrated: (hasHydrated: boolean) => void;

  paymentType: string | undefined;
  planId: number | undefined;
  setPaymentMethod: (paymentType?: PaymentTypeHandle | undefined, planId?: number | undefined) => void;
}

const usePaymentMethodStore = create<PaymentMethodState>()(
  persist(
    (set) => ({
      _hasPaymentMethodStoreHydrated: false,
      setHasHydrated: (hasHydrated) => {
        set({
          _hasPaymentMethodStoreHydrated: hasHydrated,
        });
      },

      paymentType: undefined,
      planId: undefined,
      setPaymentMethod: (paymentType, planId) => set({ paymentType, planId }),
    }),
    {
      name: 'selected-payment-method-storage',
      storage: createJSONStorage(() => customStorageDefinition),
      onRehydrateStorage: () => (state) => {
        state?.setHasHydrated(true);
      },
    }
  )
);

export default usePaymentMethodStore;
