import React, { forwardRef, ReactNode } from 'react';

import styles from './Header.module.scss';
import useInitResponseStore from '@src/store/persistent/initResponse/useInitResponseStore';
import { Link } from 'react-router-dom';

export interface Props {
  showHeader: boolean;
}

const Header = forwardRef<HTMLDivElement, Props>(({ showHeader }, ref) => {
  if (!showHeader) return null;

  return (
    <div className={styles.fixedHeader} ref={ref}>
      <div className={styles.fixedHeaderContent}>
        <HeaderLogo>
          <img className={styles.clientLogo} alt="Client Logo" />
        </HeaderLogo>
      </div>
    </div>
  );
});

interface HeaderLogoProps {
  children: ReactNode;
}

const HeaderLogo = ({ children }: HeaderLogoProps) => {
  const { client } = useInitResponseStore();

  if (!client?.url) return children;

  return (
    <Link to={client.url} className={styles.clientLogo} target="_blank" rel="noopener">
      {children}
    </Link>
  );
};

Header.displayName = 'Header';
export default Header;
