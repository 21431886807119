import { EngineContext, KapiEngineHandlesOptions } from '@src/interfaces/engineData';
import { create } from 'zustand';
import { Step, useNavStepperStages } from '@store/useNavStepperStages';
import { BPEngineHandlesOptions, ENGINE_NAV_DATA } from '@src/constants';

const continueButtonVariant: 'primary' | 'secondary' = 'primary';

export const DEFAULT_BASKET_STATE = {
  showBasket: false,
  getBasketRequestState: undefined,
  isTicketStateDirty: undefined,
  resetTicketState: undefined,
  continueButtonCallback: undefined,
  continueButtonVariant,
  enableContinueButton: false,
  showCompleteBookingLabel: false,
  showMonthlyPrice: false,
};

export type BasketStoreState = {
  mutateBasketStatus: 'error' | 'idle' | 'pending' | 'success';
  isTicketStateDirty?: boolean;
  showMonthlyPrice: boolean;
  nextStepRoute: string | null;
  enableContinueButton: boolean;
  continueButtonCallback?: () => void;
  currentEngine: BPEngineHandlesOptions | null;
  navigate: null | ((currentRoute: string) => void);
} & EngineContext;

type BasketStoreActions = {
  setBasketStore: (newState: Partial<BasketStoreState>) => void;
  setMutateBasketStatus: (newStatus: BasketStoreState['mutateBasketStatus']) => void;
  toggleMonthlyPrice: () => void;
  updateBasketNavigation: (navigate: (currentRoute: string, steps?: Step[]) => void, currentUrl: string) => void;
};

type BasketStore = BasketStoreState & BasketStoreActions;

const getCleanPathSegment = (url: string): string => {
  const pathname = url.split('?')[0].split('://').pop()?.split('/').slice(1).join('/').toLowerCase() || '';
  return pathname.replace(/^\/|\/$/g, '').split('/')[0];
};

const findCurrentStep = (pathSegment: string) =>
  Object.values(ENGINE_NAV_DATA).find((engine) => engine.route.toLowerCase().replace(/^\/|\/$/g, '') === pathSegment) ??
  null;

const determineNextStep = (steps: Step[], currentIndex: number) => {
  if (currentIndex === -1 || currentIndex === steps.length - 1) {
    return null;
  }
  return steps[currentIndex + 1];
};

const shouldEnableContinueButton = (nextStep: Step, currentStepValid: boolean) => {
  if (
    nextStep.engineHandle === KapiEngineHandlesOptions.LOGIN ||
    nextStep.engineName === KapiEngineHandlesOptions.CUSTOMER_DETAILS
  ) {
    return currentStepValid;
  }
  return nextStep?.enableNavigation ?? false;
};

export const useBasketStore = create<BasketStore>()((set, get) => ({
  ...DEFAULT_BASKET_STATE,
  mutateBasketStatus: 'idle',
  nextStepRoute: null,
  enableContinueButton: false,
  currentEngine: null,
  navigate: null,
  setBasketStore: (newState) => {
    const currentState = get();
    if (currentState.currentEngine) {
      const updatedState = {
        ...newState,
        enableContinueButton:
          newState.enableContinueButton !== undefined
            ? newState.enableContinueButton
            : currentState.enableContinueButton,
        continueButtonCallback:
          newState.continueButtonCallback !== undefined
            ? newState.continueButtonCallback
            : currentState.continueButtonCallback,
      };
      set(updatedState);
    } else {
      set(newState);
    }
  },
  setMutateBasketStatus: (newStatus) => set({ mutateBasketStatus: newStatus }),
  toggleMonthlyPrice: () => set((state) => ({ showMonthlyPrice: !state.showMonthlyPrice })),
  updateBasketNavigation: (navigate, currentUrl) => {
    const currentState = get();
    const { steps } = useNavStepperStages.getState();

    const pathSegment = getCleanPathSegment(currentUrl);
    const currentStep = findCurrentStep(pathSegment);

    if (!currentStep) {
      console.log('Current step not found for URL:', currentUrl);
      return;
    }

    const currentIndex = steps.findIndex((step) => step.engineName === currentStep.engineName);
    const nextStep = determineNextStep(steps, currentIndex);

    const common = {
      nextStepRoute: nextStep?.linkUrl || null,
      currentEngine: currentStep.handle,
      navigate: (route: string) => navigate(route, steps),
    };

    const currentStepValid = steps[currentIndex]?.valid ?? false;
    const calculatedEnableContinueButton = nextStep ? shouldEnableContinueButton(nextStep, currentStepValid) : false;

    const defaultContinueButtonCallback = () => {
      console.log('Default continue button callback called in updateBasketNavigation');
      if (nextStep?.linkUrl) {
        navigate(currentStep.route, steps);
      } else {
        console.log('No next step available');
      }
    };
    const isCustomerDetailsStep = currentStep.handle === BPEngineHandlesOptions.LOGIN;
    const updatedState = {
      ...currentState,
      ...common,
      enableContinueButton: currentState.enableContinueButton || calculatedEnableContinueButton,
      continueButtonCallback: isCustomerDetailsStep
        ? currentState.continueButtonCallback
        : defaultContinueButtonCallback,
    };

    set(updatedState);
  },
}));

export const updateAndEnableContinueButton = (state: BasketStore) => ({
  setBasketStore: state.setBasketStore,
  enableContinueButton: state.enableContinueButton,
});
