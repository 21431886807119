import { RESTART_ROUTE } from '@src/constants';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNavigateWithQueryParams } from '../useNavigateWithQueryParams/useNavigateWithQueryParams';
import { type BaseBasket } from '@src/api/useBasket/useBasket.types';
import { useDeleteBasket } from '../useDeleteBasket';

type handleRestartBasketArgType = {
  callback?: () => void;
  skipBasketDeletionBeforeRestart?: boolean;
};

type useRestartBookingArgsType = {
  basketData: BaseBasket | null | undefined;
};

function useRestartBooking({ basketData }: useRestartBookingArgsType) {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const navigate = useNavigate();
  const { deleteBasketRequest } = useDeleteBasket();

  const handleRestartBasket = useCallback(
    async ({ callback, skipBasketDeletionBeforeRestart }: handleRestartBasketArgType = {}) => {
      if (callback) {
        callback();
      }

      if (basketData && !basketData?.bookingRef && !skipBasketDeletionBeforeRestart) {
        await deleteBasketRequest();
      }

      navigateWithQueryParams(RESTART_ROUTE);
      navigate(0);
    },
    [deleteBasketRequest, navigateWithQueryParams, navigate, basketData]
  );

  return handleRestartBasket;
}

export { useRestartBooking };
