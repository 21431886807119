import styles from './LoadingDots.module.scss';
import { useEffect } from 'react';

export const LoadingDots = () => {
  useEffect(() => {
    const previousOverflowValue = String(document.body.style.overflow);

    document.body.style.height = '100%';
    document.body.style.width = '100%';

    return () => {
      document.body.style.overflow = previousOverflowValue;
      document.body.style.width = 'auto';
    };
  }, []);

  return (
    <div className={styles.loadingDots}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
        width="600"
        height="600"
        viewBox="0 0 600 600"
        style={{ width: '600', height: '600' }}
        data-testid="loadingDots"
      >
        <defs>
          <animateTransform
            repeatCount="indefinite"
            dur="1.3333333s"
            begin="0s"
            xlinkHref="#_R_G_L_2_G"
            fill="freeze"
            attributeName="transform"
            from="0 0"
            to="0 0"
            type="scale"
            additive="sum"
            keyTimes="0;0.25;0.625;1"
            values="0 0;0 0;1 1;0 0"
            keySplines="0.333 0 0 0.908;0.333 0 0 0.908;1 0 0.667 1"
            calcMode="spline"
            data-testid="animateTransform"
          />
          <animate
            repeatCount="indefinite"
            dur="1.3333333s"
            begin="0s"
            xlinkHref="#_R_G_L_2_G_M"
            fill="freeze"
            attributeName="opacity"
            from="0"
            to="1"
            keyTimes="0;0.25;0.2500013;1"
            values="0;0;1;1"
            keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
            calcMode="spline"
          />
          <animateTransform
            repeatCount="indefinite"
            dur="1.3333333s"
            begin="0s"
            xlinkHref="#_R_G_L_1_G"
            fill="freeze"
            attributeName="transform"
            from="0 0"
            to="0 0"
            type="scale"
            additive="sum"
            keyTimes="0;0.125;0.5;0.875;1"
            values="0 0;0 0;1 1;0 0;0 0"
            keySplines="0.333 0 0 0.908;0.333 0 0 0.908;1 0 0.667 1;0 0 0 0"
            calcMode="spline"
            data-testid="animateTransform"
          />
          <animate
            repeatCount="indefinite"
            dur="1.3333333s"
            begin="0s"
            xlinkHref="#_R_G_L_1_G_M"
            fill="freeze"
            attributeName="opacity"
            from="0"
            to="1"
            keyTimes="0;0.125;0.1250013;1"
            values="0;0;1;1"
            keySplines="0 0 0 0;0 0 0 0;0 0 0 0"
            calcMode="spline"
          />
          <animateTransform
            repeatCount="indefinite"
            dur="1.3333333s"
            begin="0s"
            xlinkHref="#_R_G_L_0_G"
            fill="freeze"
            attributeName="transform"
            from="0 0"
            to="0 0"
            type="scale"
            additive="sum"
            keyTimes="0;0.375;0.75;1"
            values="0 0;1 1;0 0;0 0"
            keySplines="0.333 0 0 0.908;1 0 0.667 1;0 0 0 0"
            calcMode="spline"
            data-testid="animateTransform"
          />
          <animate attributeType="XML" attributeName="opacity" dur="1s" from="0" to="1" xlinkHref="#time_group" />
        </defs>
        <g id="_R_G">
          <g id="_R_G_L_2_G_M">
            <g id="_R_G_L_2_G" transform=" translate(360, 300)">
              <path
                id="_R_G_L_2_G_D_0_P_0"
                fill="#000000"
                fillOpacity="1"
                fillRule="nonzero"
                d=" M0 -15 C8.28,-15 15,-8.28 15,0 C15,8.28 8.28,15 0,15 C-8.28,15 -15,8.28 -15,0 C-15,-8.28 -8.28,-15 0,-15z "
              />
            </g>
          </g>
          <g id="_R_G_L_1_G_M">
            <g id="_R_G_L_1_G" transform=" translate(300, 300)">
              <path
                id="_R_G_L_1_G_D_0_P_0"
                fill="#000000"
                fillOpacity="1"
                fillRule="nonzero"
                d=" M0 -15 C8.28,-15 15,-8.28 15,0 C15,8.28 8.28,15 0,15 C-8.28,15 -15,8.28 -15,0 C-15,-8.28 -8.28,-15 0,-15z "
              />
            </g>
          </g>
          <g id="_R_G_L_0_G" transform=" translate(240, 300)">
            <path
              id="_R_G_L_0_G_D_0_P_0"
              fill="#000000"
              fillOpacity="1"
              fillRule="nonzero"
              d=" M0 -15 C8.28,-15 15,-8.28 15,0 C15,8.28 8.28,15 0,15 C-8.28,15 -15,8.28 -15,0 C-15,-8.28 -8.28,-15 0,-15z "
            />
          </g>
        </g>
        <g id="time_group" />
      </svg>
    </div>
  );
};
