import ClockIcon from '../../styles/svg/basket-expire-clock.svg?react';
import { CountdownTimer } from '../CountdownTimer';
import { ExpiredBasketModalContent } from '../ModalContents/ExpiredBasketModalContent';
import { Typography } from '@kaboodle-solutions/design-library';
import styles from './BasketExpireTimer.module.scss';
import { useBasketQuery } from '@src/api/useBasket';
import { useCallback } from 'react';
import { useModalStore } from '@src/store/useModalStore';
import { useTranslation } from 'react-i18next';

export const BasketExpireTimer = () => {
  const { openModal } = useModalStore();
  const { t } = useTranslation();
  const { data: basketData } = useBasketQuery();

  const onBasketTimerExpiredCallback = useCallback(() => {
    openModal(<ExpiredBasketModalContent />, false);
  }, [openModal]);

  if (basketData?.data?.calculated?.timeRemaining) {
    return (
      <div className={styles.basketTimer}>
        <Typography tag="span" type="caption" customClass={styles.basketTimerLabel}>
          {t('basket:timerDesc')}
        </Typography>
        <ClockIcon />
        <CountdownTimer customClass={styles.basketTimerCountdown} onTimerExpired={onBasketTimerExpiredCallback} />
      </div>
    );
  }

  return <></>;
};
