import { createAndAddCssLink, transformClientIdToClientName } from './useSelectClientTheme.helper';
import { useEffect, useRef, useState } from 'react';

import useBpTokenStore from '@src/store/useBpTokenStore';
import { useSearchParams } from 'react-router-dom';
import { useStripeReturnUrlStore } from '@src/store/persistent/useStripeReturnUrlStore';

export const useSelectClientTheme = () => {
  const [currentClientTheme, setCurrentClientTheme] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const rootElement = useRef(document.querySelector('body'));
  const { c: clientIDFromStripeReturnUrl } = useStripeReturnUrlStore();

  const { bpTokenExtractedUnverified } = useBpTokenStore();
  const clientIdFromParams = searchParams.get('c');

  useEffect(() => {
    const { theme, prefix } = transformClientIdToClientName(
      clientIdFromParams || clientIDFromStripeReturnUrl || bpTokenExtractedUnverified?.c
    );

    if (theme !== currentClientTheme) {
      setCurrentClientTheme(theme);

      if (prefix) {
        createAndAddCssLink('font', theme, `https://dye1vg6h1p5m0.cloudfront.net/${prefix}/fonts/font-face.css`);
        createAndAddCssLink('theme', theme, `/client-themes/${theme}-theme.css`);
      }
    }
  }, [bpTokenExtractedUnverified?.c, currentClientTheme, clientIdFromParams, clientIDFromStripeReturnUrl]);

  useEffect(() => {
    if (!rootElement.current?.classList.contains('currentClientTheme') && currentClientTheme) {
      rootElement.current?.setAttribute('class', currentClientTheme);
    }
  }, [currentClientTheme]);
};
