import { BPEngineHandlesOptions, ENGINES, ENGINE_NAV_DATA, EngineHandles, EngineNames } from '@src/constants';

import { BaseBasket } from '@src/api/useBasket/useBasket.types';
import { InitResponse } from '@src/api/useInit';
import { KapiEngineHandlesOptions } from '@src/interfaces/engineData';
import { Steps } from '@src/store/useNavStepperStages';
import { isBasketEmpty } from '@src/lib/isBasketEmpty';

const ENGINES_REQUIRING_BASKET = new Set<EngineNames>([
  ENGINE_NAV_DATA[BPEngineHandlesOptions.LOGIN].engineName,
  ENGINE_NAV_DATA[BPEngineHandlesOptions.PAYMENT].engineName,
  ENGINE_NAV_DATA[BPEngineHandlesOptions.BOOKING_STATUS].engineName,
  ENGINE_NAV_DATA[BPEngineHandlesOptions.CHECKOUT_STATUS].engineName,
]);

const isNavigationEnabledWithoutBasket = (
  basketData: BaseBasket | null | undefined,
  currentEngineName: EngineNames
) => {
  return ENGINES_REQUIRING_BASKET.has(currentEngineName) ? !!basketData && !isBasketEmpty(basketData) : true;
};

export const shouldEnableNavigation = (
  previousSteps: Steps,
  basketData: BaseBasket | null | undefined,
  currentEngineName: EngineNames
): boolean => {
  const firstEngineAlwaysValid = previousSteps.length === 0;
  const previousMandatoryEnginesAreValid = !previousSteps.find(
    (previousEngines) => previousEngines.mandatory && !previousEngines.valid
  );

  const isPaymentEngine = currentEngineName === ENGINE_NAV_DATA['PAYMENT'].engineName;
  const enablePaymentStep = isPaymentEngine
    ? !(basketData?.calculated?.total.value === 0) &&
      !basketData?.validationErrors?.some((errors) => errors.engine === KapiEngineHandlesOptions.CUSTOMER_DETAILS)
    : true;
  const enableThisStepWithoutBasketData = isNavigationEnabledWithoutBasket(basketData, currentEngineName);

  return (
    (firstEngineAlwaysValid || previousMandatoryEnginesAreValid) && enablePaymentStep && enableThisStepWithoutBasketData
  );
};

export const validateStep = (BasketData: BaseBasket | undefined | null, EngineHandle: EngineHandles): boolean => {
  const enginesToValidate = new Set<string>([EngineHandle]);
  if (EngineHandle === BPEngineHandlesOptions.LOGIN) enginesToValidate.add(KapiEngineHandlesOptions.BASKET);

  return BasketData ? !BasketData.validationErrors?.some(({ engine }) => enginesToValidate.has(engine)) : false;
};

export const createStepsArray = (
  basketData: BaseBasket | null | undefined,
  engines: InitResponse['engines'],
  isBasketFetched: boolean
): Steps =>
  engines.reduce((acc, curr) => {
    const engineDetails = ENGINES.find((engine) => engine.handle === curr.handle);
    if (!engineDetails) return acc;
    return [
      ...acc,
      {
        engineHandle: engineDetails.handle,
        engineName: engineDetails.engineName,
        linkUrl: engineDetails.route,
        linkTitleKey: engineDetails.name,
        mandatory: curr.mandatory,
        valid: validateStep(basketData, engineDetails.handle),
        enableNavigation: shouldEnableNavigation(acc, basketData, engineDetails.engineName), // We'll set this in the next step
        basketChecked: isBasketFetched,
      },
    ];
  }, [] as Steps);
