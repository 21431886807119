import { Button, Heading, Typography } from '@kaboodle-solutions/design-library';

import styles from './RestartBasketModalContent.module.scss';
import { useBasketQuery } from '@src/api/useBasket';
import { useCallback } from 'react';
import { useModalStore } from '@src/store/useModalStore';
import { useRestartBooking } from '@src/hooks/useRestartBooking';
import { useTranslation } from 'react-i18next';

const RestartBasketModalContent = () => {
  const { t } = useTranslation('modals');
  const { closeModal } = useModalStore();
  const { data: basketData } = useBasketQuery();
  const restartBasket = useRestartBooking({ basketData: basketData?.data });

  const handleRestartBasket = useCallback(() => restartBasket({ callback: closeModal }), [closeModal, restartBasket]);

  return (
    <div className={styles.restartBasketContentContainer}>
      <Heading level="h3" isBold customClass={styles.restartBasketModalTitle}>
        {t('restartBasket.title')}
      </Heading>
      <Typography customClass={styles.restartBasketModalContent}>{t('restartBasket.content')}</Typography>
      <div className={styles.dialogModalBtnGroup}>
        <Button
          id="restart-basket-accept-btn"
          onClick={handleRestartBasket}
          labelText={t('restartBasket.acceptBtnLabel')}
        />
        <Button
          id="restart-basket-cancel-btn"
          variant="secondary"
          onClick={closeModal}
          labelText={t('restartBasket.cancelBtnLabel')}
        />
      </div>
    </div>
  );
};

export default RestartBasketModalContent;
