import { Button, Heading, Typography } from '@kaboodle-solutions/design-library';

import styles from './ExpiredBasketModalContent.module.scss';
import { useCallback } from 'react';
import { useModalStore } from '@src/store/useModalStore';
import { useRestartBooking } from '@src/hooks/useRestartBooking';
import { useTranslation } from 'react-i18next';

const ExpiredBasketModalContent = () => {
  const { t } = useTranslation('modals');
  const { closeModal } = useModalStore();
  const restartBasket = useRestartBooking({ basketData: null });

  const handleRestartBasket = useCallback(() => restartBasket({ callback: closeModal }), [closeModal, restartBasket]);

  return (
    <div className={styles.expiredContentContainer}>
      <Heading level="h3" isBold customClass={styles.expiredModalTitle}>
        {t('expiredBasket.title')}
      </Heading>
      <Typography customClass={styles.expiredModalContent}>{t('expiredBasket.content')}</Typography>
      <Button id="expired-basket-btn" onClick={handleRestartBasket} labelText={t('expiredBasket.acceptBtnLabel')} />
    </div>
  );
};

export default ExpiredBasketModalContent;
